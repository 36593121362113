import TextField from "@mui/material/TextField";
import React from "react";
import { userActions } from "../_actions";
import { Snack } from "../_components";
import CustomizedButton from "../_components/buttons/CustomizedButton";
import { responseErrorToText, store } from "../_helpers";
import { cn as lang_cn } from '../_lang/cn.json';
import { en as lang_en } from '../_lang/en.json';
import { ru as lang_ru } from '../_lang/ru.json';
import { userService } from "../_services";
import { NoAuthPage } from "./NoAuthPage";


export default class PasswordResetPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      password_confirmation: "",
      alertShow: false,
      errType: "error",
      snackText: "",
    };
    this.params = new URLSearchParams(this.props.location.search);
    
  }

  componentDidMount() {

    let lang = localStorage.getItem("navic_lang") ?? "ru";
	this.setState({lang});

  }


  getI18N = (textKey) => 
  {
	  const { lang } = this.state;
	  
	  if (!lang) return;
	  
	  if (lang === 'ru')
	  {
		  if (Object.keys(lang_ru.translation).indexOf(textKey)>=0)
			return lang_ru.translation[textKey];
	  }
		
		if (lang === 'en')
			if (Object.keys(lang_en.translation).indexOf(textKey)>=0)
			return lang_en.translation[textKey];
		
		if (lang === 'cn')
			if (Object.keys(lang_cn.translation).indexOf(textKey)>=0)
			return lang_cn.translation[textKey];
	  
	  return textKey;
  }


  resetPassword = (e) => {
    e.preventDefault();
    store.dispatch(userActions.loading(true));
    userService.postWrapper(
      "api/password/reset",
      {
        email: this.params.get("email"),
        token: this.params.get("token"),
        password: this.state.password,
        password_confirmation: this.state.password_confirmation,
      },
      (data) => {
        store.dispatch(userActions.loading(false));
        this.setState({
          snackText: this.getI18N("password_has_been_changed"),
          alertShow: true,
          errType: "success",
        });
      },
      (error) => {
        store.dispatch(userActions.loading(false));
        this.setState({
          snackText: responseErrorToText(error),
          alertShow: true,
          errType: "error",
        });
      }
    );
  };

  render() {
    const form = (
      <form onSubmit={this.resetPassword}>
        <h3>{this.getI18N("reset_password") +" "+ this.params.get("email")}</h3>
        <div>
          <TextField
            label={this.getI18N("password")}
            type="password"
            onChange={(e) => this.setState({ password: e.target.value })}
            variant="standard"
          />
        </div>
        <div>
          <TextField
            label={this.getI18N("password_confirmation")}
            type="password"
            onChange={(e) =>
              this.setState({ password_confirmation: e.target.value })
            }
            variant="standard"
          />
        </div>
        <CustomizedButton
          type="submit"
          title={this.getI18N("refresh_password")}
          prim
          className="form-button"
        />
      </form>
    );
    const snack = (
      <Snack
        open={this.state.alertShow}
        variant={this.state.errType}
        text={this.state.snackText}
        onClose={() => this.setState({ alertShow: false })}
      />
    );
    return <NoAuthPage form={form} snack={snack} />;
  }
}
