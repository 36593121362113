import { CircularProgress } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";

import i18next from "i18next";
import { BuildingsPage } from "../BuildingsPage/BuildingsPage";
import { ClientConfPage, ClientListPage } from "../ClientPages";
import { TicketsPage } from "../ClientPages/TicketsPage";
import CookiePopup from "../MainPage/CookiePopup";
import { TasksPage } from "../Pages/TasksPage";
import { PartPage } from "../PartPage/PartPage";
import { SpeakersPage } from "../SpeakersPage/SpeakersPage";
import { SponsorPage } from "../SponsorPage/SponsorPage";
import { RolePage } from "../TeamPage/RolePage";
import { TeamPage } from "../TeamPage/TeamPage";
import { history, store } from "../_helpers";
import { confService, userService } from "../_services";
import { ConfListPage } from "./ConfListPage";
import { ConfNavPage } from "./ConfNavPage";
import { CreateConfPage } from "./CreateConfPage";
import { HelpPage } from "./HelpPage";
import { SettingsPage } from "./SettingsPage";
import { SuppPage } from "./SuppPage";

import { Snack } from "../_components";
import "../css/style.css";
import "../fonts/fonts.css";

import TimeTable from "../TimePage/TimeTable";
import { appActions, userActions } from "../_actions";
import { AppMenu } from "../_components/AppMenu";
import { HeadMenu } from "../_components/HeadMenu";

const USER_ORG = "org";
const USER_MEMBER = "team_member";
const USER_STUDENT = "student";

class MainPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      companies: [],
      hoverIndex: -1,
      isEdit: false,
      userType: "",
      userName: "",
      email: "",
      err_type: "error",
      menuOpen: true,
      loadConfInfo: false,
      loadTicketInfo: false,
      confInfo: null,
      tid: 0,
      confName: "",
      tickets: [],
      vertical: "top",
      horizontal: "center",
      confsLoaded: false,
      userInfoLoaded: false,
    };
    store.subscribe(this.storeChange);
  }

  storeChange = (e) => {
    const self = this;

    if (store.getState().user.error) {
      const err1 = `${store.getState().user.error}`;
      let err_tp = "error";
      let err_action = 0;

      let verify_notif_text = i18next.t("mail_aprrove_sending");

      if (store.getState().user.err_type)
        err_tp = store.getState().user.err_type;

      if (store.getState().user.err_action)
        err_action = store.getState().user.err_action;

      if (err1) {
        if (err_action != 1)
          this.setState({ snackText: err1, alertShow: true, err_type: err_tp });

        if (err_action == 1) {
          alert(err1);
          self.props.dispatch(userActions.disable_error());

          userService.postWrapper(
            "api/email/verification-notification",
            {},
            (data) => {
              alert(verify_notif_text);
            },
            (error) => {
              console.log(error);

              if (error != "") {
                self.props.dispatch(
                  userActions.set_error(i18next.t("request_exec_error"))
                );
              }
            }
          );
        }
      } else
        this.setState({
          snackText: i18next.t("request_exec_error"),
          alertShow: true,
          err_type: "error",
        });

      setTimeout(() => {
        self.props.dispatch(userActions.disable_error());
      }, 100);

      if (err1.indexOf("401") >= 0 && err_tp == "error") history.push("/login");
    }

    if (store.getState().user.info && store.getState().user.info.type) {
      const userType = store.getState().user.info.type;

      this.setState({
        userType: userType,
        userName: store.getState().user.info.name,
        email: store.getState().user.info.email,
      });
    }
  };

  componentDidMount() {
    this.setLanguage(this.props.app.lang);

    let pp = this.props.match;
    console.log(pp);

    const userStrData = localStorage.getItem("user");

    if (userStrData && userStrData != "{}") {
      try {
        const objData = JSON.parse(userStrData);
        console.log(objData);
        this.props.dispatch(userActions.setuser(objData));
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log(userStrData);
      this.props.dispatch(userActions.getuser());
    }

    const strData = localStorage.getItem("conf");
    if (strData != "null") {
      try {
        const objData = JSON.parse(strData);
        this.props.dispatch(userActions.set_conf(objData));
      } catch (e) {
        console.log(e);
      }
    }

    localStorage.setItem("navic_redirect_count", "");

    const url = window.location.pathname;
    const self = this;

    if (url.indexOf("/purchased") >= 0) {
      const url_arr = url.replace("/purchased-", "").split("-");

      if (url_arr.length > 1) {
        this.setState({ tid: url_arr[1] });
        confService.confInfo(
          url_arr[0],
          (data) => {
            self.setState({
              confName: data.data.name,
              confInfo: data.data,
              loadConfInfo: true,
            });
            console.log(data.data);

            if (self.state.loadConfInfo)
              self.getConfTicket(
                data.data.name,
                self.state.tickets,
                url_arr[0]
              );
          },
          (err) => {
            console.log(err);
          }
        );

        confService.ticketsInfo(
          url_arr[0],
          (data) => {
            self.setState({
              tickets: data.data,
              confInfo: data.data,
              loadTicketInfo: true,
            });

            if (self.state.loadConfInfo)
              self.getConfTicket(self.state.confName, data.data, url_arr[0]);
          },
          (err) => {
            console.log(err);
          }
        );
      }
    } else {
      const url1 = url.replace("/", "");

      const urls = url1.split("/");
      let url0 = urls[0];

      let id = 0;
      if (urls.length > 1) id = urls[1];

      if (url0 == "") url0 = "confs";

      this.props.dispatch(appActions.setpage(url0, id));
    }

  }

  setLanguage(language) {
    i18next.init({
      lng: language,
      resources: require(`../_lang/${language}.json`),
    });

    this.props.dispatch(appActions.setlang(language));
  }

  getConfTicket = (name, tickets, conf_id) => {
    const { tid } = this.state;

    let tname = "";
    let tcost = "";

    for (let i = 0; i < tickets.length; i++) {
      if (tickets[i].id == tid) {
        tname = tickets[i].name;
        tcost = tickets[i].cost;
      }
    }

    if (tcost != "" && tcost > 0) {
      this.props.dispatch(
        userActions.set_error(
          i18next.t("you_bought_ticket") +
            " " +
            tname +
            " " +
            i18next.t("on_event") +
            " " +
            name,
          "info"
        )
      );
    } else {
      this.props.dispatch(
        userActions.set_error(
          i18next.t("you_reg_on_event") + " " + name,
          "info"
        )
      );
    }

    this.props.dispatch(appActions.setpage("conf_view", conf_id));
    // history.push('/conf_view/'+conf_id)
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  onCloseSnack = () => {
    this.setState({ alertShow: false });
  };

  clientMenuPress = (page) => {
    this.props.dispatch(appActions.setpage(page));
  };

  render() {
    const self = this;
    const {
      userType,
      userName,
      menuOpen,
      email,
      alertShow,
      snackText,
      err_type,
      vertical,
      horizontal,
    } = this.state;
    const { page } = this.props.app;
    const { user } = this.props;

    // <AppMenu menu={this.state.menu} selectedMenu={this.state.location} open={this.state.open}/>

    if (
      window.location.href.indexOf("ticket-moderation") >= 0 ||
      window.location.href.indexOf("votes") >= 0
    )
      return <div></div>;

    if (userType == USER_STUDENT) {
      return (
        <div>
          <HeadMenu
            name={userName}
            email={email}
            type={userType}
            menuPress={this.clientMenuPress}
          />
          <div className="layout_main">
            {userType == USER_STUDENT && page == "confs" && <ClientListPage />}
            {userType == USER_STUDENT && page == "conf_view" && (
              <ClientConfPage />
            )}
            {userType == USER_STUDENT && page == "tickets" && <TicketsPage />}
          </div>
        </div>
      );
    } else
      return (
        <div style={{ height: "100vh", minWidth: 1600 }}>
          <AppMenu
            open={menuOpen}
            onOpen={() => self.setState({ menuOpen: !menuOpen })}
            userType={userType}
            conf={user.selectedConf}
          />
          <div className={menuOpen ? "wrapper__open" : "wrapper"}>
            <HeadMenu name={userName} email={email} type={userType} />
            <div className="layout_main">
              {(userType == USER_ORG || userType == USER_MEMBER) &&
                page == "events" && <ClientListPage />}

              {(userType == USER_ORG || userType == USER_MEMBER) &&
                (page == "confs" || page == "confs_title") && <ConfListPage />}
              {(userType == USER_ORG || userType == USER_MEMBER) &&
                page == "new_conf" && <CreateConfPage />}
              {(userType == USER_ORG || userType == USER_MEMBER) &&
                page == "conf_param" && <CreateConfPage />}
              {(userType == USER_ORG || userType == USER_MEMBER) &&
                page == "team_org" && (
                  <TeamPage
                    conf_id={user.selectedConf ? user.selectedConf.id : -1}
                  />
                )}
              {(userType == USER_ORG || userType == USER_MEMBER) &&
                page == "role_org" && (
                  <RolePage
                    conf_id={user.selectedConf ? user.selectedConf.id : -1}
                  />
                )}
              {(userType == USER_ORG || userType == USER_MEMBER) &&
                page == "client_view" && <ClientConfPage isOrg={true} />}
              {(userType == USER_ORG || userType == USER_MEMBER) &&
                page == "speakers" && <SpeakersPage />}
              {(userType == USER_ORG || userType == USER_MEMBER) &&
                page == "sponsors" && <SponsorPage />}
              {(userType == USER_ORG || userType == USER_MEMBER) &&
                page == "blds" && <BuildingsPage />}
              {(userType == USER_ORG || userType == USER_MEMBER) &&
                page == "partips" && (
                  <PartPage
                    conf_id={
                      user.selectedConf && user.selectedConf.id
                        ? user.selectedConf.id
                        : -1
                    }
                    conf_name={
                      user.selectedConf && user.selectedConf.id
                        ? user.selectedConf.name
                        : ""
                    }
                  />
                )}
              {(userType == USER_ORG || userType == USER_MEMBER) &&
                page == "nav" && <ConfNavPage />}
              {(userType == USER_ORG || userType == USER_MEMBER) &&
                page == "conf_prog" && (
                  <TimeTable
                    conf_data={user.selectedConf}
                    building_id={
                      user.selectedConf ? user.selectedConf.building_id : -1
                    }
                    conf_id={user.selectedConf ? user.selectedConf.id : -1}
                  />
                )}

              {(userType == USER_ORG || userType == USER_MEMBER) &&
                page == "helpinfo" && <HelpPage />}
              {(userType == USER_ORG || userType == USER_MEMBER) &&
                page == "settings" && <SettingsPage />}
              {(userType == USER_ORG || userType == USER_MEMBER) &&
                page == "support" && <SuppPage />}
              {(userType == USER_ORG || userType == USER_MEMBER) &&
                page == "tasks" && <TasksPage />}
            </div>
          </div>
          <Snack
            open={alertShow}
            variant={err_type}
            text={snackText}
            onClose={this.onCloseSnack}
          />
          {user.isLoading && (
            <div className="shadow">
              <div className="shadow_circle">
                <CircularProgress />
              </div>
            </div>
          )}
          <CookiePopup />
        </div>
      );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedMainPage = connect(mapStateToProps)(MainPage);
export { connectedMainPage as MainPage };
