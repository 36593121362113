import { CircularProgress } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import React from "react";
import { connect } from "react-redux";
import { cn as lang_cn } from '../_lang/cn.json';
import { en as lang_en } from '../_lang/en.json';
import { ru as lang_ru } from '../_lang/ru.json';

import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from '@mui/material/IconButton';
import CustomizedButton from "../_components/buttons/CustomizedButton";

import { Snack, TextFieldCell } from "../_components";
import { history, initLanguage, store } from "../_helpers";

import CookiePopup from "../MainPage/CookiePopup";
import { userActions } from "../_actions";
import { serverUrl } from "../_constants";
import "../css/style.css";

import LeaderLogo from "../img/leader_id.png";
import vkLogo from "../img/vk.png";



const loginIsEmail = process.env.REACT_APP_LOGIN_IS_EMAIL;


class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // reset login status

    this.state = {
      username: "",
      pwd: "",
      submitted: false,
      alertShow: false,
      err_type: "",
      snackText: "",
	  showPwd: false,
    };

    store.subscribe(this.storeChange);
  }

  storeChange = (e) => {
    if (
      store.getState().authentication.error &&
      store.getState().authentication.isLoading == false
    ) {
      this.setState({
        snackText: (loginIsEmail?this.getI18N("incorrect_email_pwd"):this.getI18N("incorrect_login_pwd")),
        alertShow: true,
        err_type: "error",
      });
    }
  };

  componentDidMount() {
    const { dispatch } = this.props;

    const username = localStorage.getItem("navic_user");
    const pwd = localStorage.getItem("navic_pwd");
	
	localStorage.setItem('nc_usertype','');
	
	let lang = localStorage.getItem("navic_lang") ?? "ru";
	this.setState({lang});

    if (username && pwd) {
      this.setState({ username, pwd });
      dispatch(userActions.login({ username, password: pwd }));
    }
  }

  getI18N = (textKey) => 
  {
	  const { lang } = this.state;
	  
	  if (!lang) return;
	  
	  if (lang == 'ru')
	  {
		  if (Object.keys(lang_ru.translation).indexOf(textKey)>=0)
			return lang_ru.translation[textKey];
	  }
		
		if (lang == 'en')
			if (Object.keys(lang_en.translation).indexOf(textKey)>=0)
			return lang_en.translation[textKey];
		
		if (lang == 'cn')
			if (Object.keys(lang_cn.translation).indexOf(textKey)>=0)
			return lang_cn.translation[textKey];
	  
	  return textKey;
  }

  

  onCloseSnack = () => {
    this.setState({ alertShow: false });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  openReg = () => {
    // alert(window.location.href);
    history.push("/registration");
  };

  handleSubmit = (e) => {
    const self = this;

    e.preventDefault();

    this.setState({ submitted: true });
    const { username, pwd } = this.state;
    const { dispatch } = this.props;

    if (username.length > 0 && pwd.length > 0) {
      localStorage.setItem("user", "{}");

      dispatch(userActions.login({ username, password: pwd }));
    } else {
      this.setState({
        snackText: this.getI18N("enter_email_pwd"),
        alertShow: true,
        err_type: "warning",
      });
    }
  };

  render() {
    initLanguage();

    const { error, isLoading } = this.props;

    const {
      username,
      submitted,
      pwd,
      alertShow,
      err_type,
      snackText,
	  showPassword,
    } = this.state;

    let errorMsg = this.getI18N("incorrect_token");
    if (error) {
      if (error === "Forbidden") errorMsg = this.getI18N("incorrect_token");
    }

    return (
      <div style={{ width: "100%", height: "100%", verticalAlign: "center" }}>
        <div style={{ width: 400, margin: "0 auto", paddingTop: "30vh" }}>
          <form name="form" onSubmit={this.handleSubmit}>
            <div className={submitted && !username ? " has-error" : ""}>
              <TextFieldCell
                id="login"
				label={loginIsEmail?this.getI18N('user_email'):this.getI18N('user_login')}
                name="username"
                type="text"
                value={username}
                onChange={this.handleChange}
				autoFocus
              />
            </div>
            <div className={submitted && !username ? " has-error" : ""}>
              <TextField
				style={{display:'flex',marginBottom:20}}
                id="pwd"
                label={this.getI18N('password')}
                name="pwd"
                type={showPassword?"text":"password"}
                value={pwd}
                onChange={this.handleChange}
				 InputProps={{
						style:{fontSize: 24,    
						fontWeight: "bold",
						color: "#0b1218"},
						 endAdornment:  
						   <InputAdornment position="end">
							  <IconButton
								onClick={()=>this.setState({showPassword:!showPassword})}
								edge="end"
							  >
								{showPassword ? <Visibility /> : <VisibilityOff />}
							  </IconButton>
							</InputAdornment>
						 
					   }}
              />
            </div>

            <div
              className="form-group"
              style={{
                marginTop: 5,
                justifyContent: "space-between",
                flex: 1,
                display: "flex",
              }}
            >
              <CustomizedButton
                style={{ marginBottom: 5 }}
                id="category-button-add"
                type="submit"
                title={this.getI18N('login_enter')}
                prim
              />
              <CustomizedButton
                style={{ marginBottom: 5 }}
                onClick={this.openReg}
                id="category-button-add"
                title={this.getI18N('registration')}
                prim
              />
            </div>
          </form>
		  <div style={{marginTop:10,textDecoration:'underline'}}>
          <a href="/password-forgot" className="forgot-password-link">{this.getI18N("forgot_password")}</a>
		  </div>
		  
          
        </div>
		
            <div style={{marginTop:20, display:'flex',flexDirection:'row',justifyContent:'center'}}>
          <a
            href={serverUrl + "auth/vkontakte"}
          >
          
            <img src={vkLogo}/>
            </a>
            <a
            href={serverUrl + "auth/leader-id"}
            style={{ marginLeft:40 }}
          >
            <img src={LeaderLogo}/>
            </a>
            </div>

        {isLoading && (
          <div className="shadow">
            <div className="shadow_circle">
              <CircularProgress />
            </div>
          </div>
        )}
        <CookiePopup />
        <Snack
          open={alertShow}
          variant={err_type}
          text={snackText}
          onClose={this.onCloseSnack}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn, error, isLoading } = state.authentication;
  return {
    loggingIn,
    isLoading,
    error,
  };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export { connectedLoginPage as LoginPage };

