import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextFieldI18N from "../_components/TextFieldI18N";
import { useTranslation, Trans } from "react-i18next";

const LANG_LIST = ["ru", "en", "cn"];

export default function BldDialog(props) {
  const [address, setAddress] = React.useState("");
  const [name, setName] = React.useState("");
  const [description, setDesc] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [person, setPerson] = React.useState("");
  const [comment, setComment] = React.useState("");
  const [name_i18n, setNameI18N] = React.useState({});
  const [description_i18n, setDescI18N] = React.useState({});

  const [count, setCount] = React.useState(0);

  const {
    handleClose,
    dAddress,
    dName,
    dEmail,
    dDesc,
    dPerson,
    dPhone,
    dComment,
    dNameI18N,
    dDescI18N,
    id,
    dialogOpen,
  } = props;

  const { t } = useTranslation();

  React.useEffect(() => {
    if (count === 0) {
      setAddress(dAddress);
      setName(dName);
      setDesc(dDesc);
      setEmail(dEmail);
      setPhone(dPhone);
      setPerson(dPerson);
      setComment(dComment);
      setNameI18N(dNameI18N);
      setDescI18N(dDescI18N);
    }
  }, [count]);

  function saveData(e) {
    e.preventDefault();

    if (id === 0) {
      if (address !== "") {
        handleClose({
          address,
          name,
          email,
          phone,
          person,
          comment,
          name_i18n,
          description_i18n,
          description,
        });
        setCount(0);
      }
    } else if (address !== "" && id > 0) {
      handleClose({
        address,
        name,
        email,
        phone,
        person,
        comment,
        name_i18n,
        description_i18n,
        description,
      });
      setCount(0);
    }
  }

  function cancelData() {
    handleClose("");
    setCount(0);
  }

  return (
    <Dialog open={dialogOpen}>
      <DialogTitle>{t("bld")}</DialogTitle>
      <form onSubmit={saveData}>
        <DialogContent style={{ width: 500 }}>
          <TextFieldI18N
            autoFocus
            margin="dense"
            id="name"
            label={t("title")}
            fullWidth
            variant="standard"
            value={name_i18n}
            required
            onChange={(e, tabName) => {
              let tmpI18N = name_i18n;
              tmpI18N[tabName] = e.target.value;

              setNameI18N(tmpI18N);
              setName(tmpI18N["ru"]);
              setCount(count + 1);
            }}
            langList={LANG_LIST}
          />
          <TextField
            margin="dense"
            id="address"
            label={t("dialog_address")}
            fullWidth
            variant="standard"
            value={address}
            required
            multiline
            onChange={(e) => {
              setAddress(e.target.value);
              setCount(count + 1);
            }}
          />
          <TextFieldI18N
            margin="dense"
            id="description_i18n"
            label={t("dialog_desc")}
            fullWidth
            variant="standard"
            value={description_i18n}
            required
            multiline
            onChange={(e, tabName) => {
              let tmpI18N = description_i18n;
              tmpI18N[tabName] = e.target.value;

              setDescI18N(tmpI18N);
              setDesc(tmpI18N["ru"]);
              setCount(count + 1);
            }}
            langList={LANG_LIST}
          />

          <TextField
            margin="dense"
            id="email"
            label={t("dialog_email")}
            fullWidth
            variant="standard"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setCount(count + 1);
            }}
          />
          <TextField
            margin="dense"
            id="person"
            label={t("dialog_contact")}
            fullWidth
            variant="standard"
            value={person}
            onChange={(e) => {
              setPerson(e.target.value);
              setCount(count + 1);
            }}
          />
          <TextField
            margin="dense"
            id="phone"
            label={t("dialog_phone")}
            fullWidth
            variant="standard"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              setCount(count + 1);
            }}
          />
          <TextField
            margin="dense"
            id="comment"
            label={t("dialog_comment")}
            fullWidth
            variant="standard"
            value={comment}
            multiline
            onChange={(e) => {
              setComment(e.target.value);
              setCount(count + 1);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">{t("btn_save")}</Button>
          <Button onClick={cancelData}>{t("btn_cancel")}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
