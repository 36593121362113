import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useDispatch } from "react-redux";
import { userActions } from "../_actions";

import "../css/style.css";
import i18next from "i18next";

const firmsList = [];

export default function UserInfoDialog(props) {
  const [data, setData] = React.useState({});
  const [count, setCount] = React.useState(0);
  
  const { handleClose, flds, dialogOpen } = props;
  const [firm, setFirm] = React.useState(null);

  function openPdf() {
    window.open(process.env.REACT_APP_USER_AGR, "_blank");
  }

  function cancelData() {
    handleClose(null);
    setCount(0);
    setData(null);
  }

  function saveData() {
    if (data && flds) {
      let isOk = true;

      for (let i = 0; i < flds.length; i++)
        if (!data[flds[i]]) isOk = false;

        /*
      if (firm) {
        data["department"] = firm.label;
      }
      */

      if (isOk) {
        console.log(data);
        handleClose({ ...data });
        setCount(0);
        setData(null);
        //setFirm(null);
      } else 
      {
         alert(i18next.t("too_short_fields"));
      }
      //dispatch(userActions.set_error(i18next.t("too_short_fields")));
    }
  }

  function setInfo(name, val) {
    let tmpData = { ...data };
    tmpData[name] = val;
    setData(tmpData);
  }

  return (
    <Dialog open={dialogOpen}>
      <DialogTitle>{i18next.t("fill_anket")}</DialogTitle>
      <DialogContent style={{ minWidth: 280, maxWidth: 580 }}>
        {flds &&
          flds
            .filter((e) => e !== "department")
            .map(function (item, index) {
              return (
                <TextField
                  autoFocus={index === 0}
                  margin="dense"
                  id={item}
                  label={i18next.t("ank_" + item)}
                  fullWidth
                  variant="standard"
                  value={data && data[item] ? data[item] : ""}
                  required
                  onChange={(e) => setInfo(item, e.target.value)}
                />
              );
            })}
        <div style={{ marginTop: 20, fontSize: 16 }}>
          {i18next.t("agr_pd_1")}
          <span
            style={{
              color: "#0f2d51",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={openPdf}
          >
            {i18next.t("agr_pd_2")}
          </span>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={saveData}>{i18next.t("registry")}</Button>
        <Button onClick={cancelData}>{i18next.t("btn_cancel")}</Button>
      </DialogActions>
    </Dialog>
  );
}
