import React from "react";
import { serverUrl } from "../_constants";
import { confService } from "../_services";
import "../css/style.css";
import confLogo from "../img/conf_logo.png";
import offlineLogo from "../img/offline.png";
import onlineLogo from "../img/online.png";
import i18next from "i18next";

export default function ConfItem(props) {
  const { data } = props;

  return (
    <li className="conf-list__item">
      <div className="card" onClick={props.onClick}>
        <div className="card__top" onClick={props.onEdit}>
          <img
            src={data.image ? serverUrl + data.image : confLogo}
            alt=""
            className="object-cover"
          />
          <div>
            {data.type === "offline" && (
              <span className="card__label">
                <span>{i18next.t("offline_text")}</span>
                <img style={{ marginLeft: 10 }} src={offlineLogo} alt="" />
              </span>
            )}
            {data.type === "online" && (
              <span className="card__label">
                <span>{i18next.t("online_text")}</span>
                <img style={{ marginLeft: 10 }} src={onlineLogo} alt="" />
              </span>
            )}
            {data.type === "hybrid" && (
              <span className="card__label">
                <span>{i18next.t("hybrid_text")}</span>
                <img style={{ marginLeft: 10 }} src={onlineLogo} alt="" />
                <img style={{ marginLeft: 10 }} src={offlineLogo} alt="" />
              </span>
            )}
          </div>
        </div>

        <div className="card__body">
          <p style={{ marginBottom: 10, fontSize: 18, fontWeight: 500 }}>
            {data.name}
          </p>

          <p
            style={{
              marginBottom: 10,
              marginTop: 5,
              fontSize: 14,
              fontWeight: 500,
            }}
          >
            {data.is_published == 1 ? i18next.t("published") : i18next.t("not_published") }
          </p>
          <div className="layout__inf">
            <ul className="layout__inf-list">
              <li className="layout__inf-item">
                <a onClick={props.onEdit} className="card__link">
                  <span>{i18next.t("btn_edit")}</span>
                </a>
              </li>
              <li className="layout__inf-item">
                <a onClick={props.onDelete} className="card__link">
                  <span>{i18next.t("btn_delete")}</span>
                </a>
              </li>
            </ul>
          </div>

          <div className="layout__inf">
            <p className="layout__inf-text">
              {confService.getConfDateTimeStr(data.start_time, data.end_time)}
            </p>

            {!data.building_id && (
              <p className="layout__inf-text">{i18next.t("no_adress")}</p>
            )}
            {data.building_id && (
              <p className="layout__inf-text">{data.building_id.address}</p>
            )}

            <ul className="layout__inf-blocks">
              <li className="layout__inf-block">
                <div className="layout__inf-media">
                  <svg width="24" height="24">
                    <use href="./img/sprite.svg#user-icon" />
                  </svg>

                  <p className="layout__inf-contains">
                    <strong>
                      {data.tickets_count ? data.tickets_count : "0"}
                    </strong>{" "}
                    <br />
                    {i18next.t("part")}
                  </p>
                </div>
              </li>

              <li className="layout__inf-block">
                <div className="layout__inf-media">
                  <svg width="24" height="24">
                    <use href="./img/sprite.svg#star-icon" />
                  </svg>

                  <p className="layout__inf-contains">
                    <strong>
                      {data.sponsors_count ? data.sponsors_count : "0"}
                    </strong>{" "}
                    <br />
                    {i18next.t("spons")}
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <a className="card__link" onClick={props.onSchedule}>
            <span>{i18next.t("schedule")}</span>
          </a>
        </div>
      </div>
    </li>
  );
}
