import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { useTranslation, Trans } from "react-i18next";
import "../css/style.css";
import { serverUrl } from "../_constants";
import TextFieldI18N from "../_components/TextFieldI18N";

const LANG_LIST = ["ru", "en", "cn"];

export default function MemberDialog(props) {
  const [name, setName] = React.useState("");
  const [description, setDesc] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [city, setCity] = React.useState("");
  const [comm, setComm] = React.useState("");
  const [name_i18n, setNameI18N] = React.useState({});
  const [description_i18n, setDescI18N] = React.useState({});
  const [city_i18n, setCityI18N] = React.useState({});
  const [comment_i18n, setCommI18N] = React.useState({});
  const [count, setCount] = React.useState(0);

  const {
    handleClose,
    title,
    dialogOpen,
    dName,
    dPhone,
    dMail,
    dDesc,
    dComm,
    dCity,
    dNameI18N,
    dDescI18N,
    dCommI18N,
    dCityI18N,
    id,
    image,
    uploadImage,
    imageStatus,
  } = props;

  const { t} = useTranslation();

  React.useEffect(() => {
    if (count === 0) {
      setPhone(dPhone);
      setName(dName);
      setDesc(dDesc);
      setCity(dCity);
      setEmail(dMail);
      setComm(dComm);
      setNameI18N(dNameI18N);
      setDescI18N(dDescI18N);
      setCityI18N(dCityI18N);
      setCommI18N(dCommI18N);
      
    }
  }, [
    count,
    dPhone,
    dName,
    dDesc,
    dCity,
    dMail,
    dComm,
    dCityI18N,
    dNameI18N,
    dCommI18N,
    dDescI18N,
  ]);

  function saveData(e) {
    e.preventDefault();

    if (name !== "" || (name_i18n && name_i18n["ru"])) {
      handleClose({
        name,
        phone,
        city,
        description,
        email,
        comment: comm,
        name_i18n,
        city_i18n,
        description_i18n,
        comment_i18n,
      });
      setCount(0);
    }
  }

  function cancelData() {
    handleClose("");
    setCount(0);
  }

  function onDrop(acceptedFiles, rejectedFiles) {
    console.log(acceptedFiles);

    if (acceptedFiles.length > 0) {
      uploadImage(acceptedFiles[0]);
    }
  }

  return (
    <Dialog open={dialogOpen}>
      <DialogTitle>{title}</DialogTitle>
      <form onSubmit={saveData}>
        <DialogContent>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Dropzone onDrop={onDrop} class="visually--hidden">
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div
                  style={{
                    textAlign: "center",
                    verticalAlign: "center",
                    width: 250,
                    height: 250,
                    background: "#eee",
                    borderRadius: 5,
                    border: "1px solid #000",
                  }}
                  {...getRootProps()}
                  className={classNames("dropzone", {
                    "dropzone--isActive": isDragActive,
                  })}
                >
                  <input {...getInputProps()} />

                  {image && imageStatus === 0 && (
                    <img
                      src={image ? serverUrl + image : ""}
                      alt=""
                      className="object-cover"
                    />
                  )}
                  {imageStatus === 0 &&
                    (isDragActive ? (
                      <p
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t("dropzone_file_upload")}
                      </p>
                    ) : (
                      <p
                        style={{
                          fontSize: 12,
                          position: "relative",
                          top: image ? -135 : 100,
                          left: 15,
                          width: 220,
                          background: "#eee",
                          padding: 10,
                          borderRadius: 5,
                        }}
                      >
                       {t("dropzone_title_hint")}
                      </p>
                    ))}
                  {imageStatus === 1 && (
                    <p
                      style={{
                        fontSize: 12,
                        position: "relative",
                        top: 110,
                      }}
                    >
                      {t("dropzone_file_uploaded")}
                    </p>
                  )}
                </div>
              )}
            </Dropzone>
          </div>
          <TextFieldI18N
            autoFocus={true}
            margin="dense"
            id="name"
            label={t("dialog_name")}
            fullWidth
            variant="standard"
            value={name_i18n}
            required
            onChange={(e, tabName) => {
              let tmpI18N = name_i18n;
              tmpI18N[tabName] = e.target.value;

              setNameI18N(tmpI18N);
              setName(tmpI18N["ru"]);
              setCount(count + 1);
            }}
            langList={LANG_LIST}
          />
          <TextFieldI18N
            margin="dense"
            id="desc"
            label={t("dialog_desc")}
            fullWidth
            variant="standard"
            value={description_i18n}
            langList={LANG_LIST}
            onChange={(e, tabName) => {
              let tmpI18N = description_i18n;
              tmpI18N[tabName] = e.target.value;

              setDescI18N(tmpI18N);
              setDesc(tmpI18N["ru"]);
              setCount(count + 1);
            }}
          />
          <TextField
            margin="dense"
            id="email"
            label={t("dialog_email")}
            fullWidth
            variant="standard"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setCount(count + 1);
            }}
          />
          <TextField
            margin="dense"
            id="phone"
            label={t("dialog_phone")}
            fullWidth
            variant="standard"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              setCount(count + 1);
            }}
          />
          <TextFieldI18N
            margin="dense"
            id="phone"
            label={t("dialog_city")}
            fullWidth
            variant="standard"
            value={city_i18n}
            langList={LANG_LIST}
            onChange={(e,tabName) => {
              let tmpI18N = city_i18n;
              tmpI18N[tabName] = e.target.value;

              setCityI18N(tmpI18N);
              setCity(tmpI18N["ru"]);
              setCount(count + 1);
            }}
          />
          <TextFieldI18N
            margin="dense"
            id="phone"
            label={t("dialog_comment")}
            fullWidth
            variant="standard"
            value={comment_i18n}
            multiline={true}
            langList={LANG_LIST}
            onChange={(e,tabName) => {
              let tmpI18N = comment_i18n;
              tmpI18N[tabName] = e.target.value;

              setCommI18N(tmpI18N);
              setComm(tmpI18N["ru"]);
              setCount(count + 1);
              
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">{t("btn_save")}</Button>
          <Button onClick={cancelData}>{t("btn_cancel")}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
