import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import i18next from "i18next";
import "../../css/style.css";
import "../../css/Pages/EntityPage.css";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { EntityPage } from "./EntityPage";
import { userService } from "../../_services";
import { store } from "../../_helpers";
import { userActions } from "../../_actions";

class UpsertDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: props.dialogFields,
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.dialogFields !== prevProps.dialogFields) {
      this.setState({
        fields: this.props.dialogFields,
      });
    }
  }

  saveData = (e) => {
    e.preventDefault();

    for (let field in this.state.fields) {
      if (
        this.state.fields[field].required &&
        !this.state.fields[field].value
      ) {
        return;
      }
    }
    this.closeDialog(this.state.fields);
  };

  cancelData = () => {
    this.props.closer();
  };

  text = (item) => {
    const options = this.state.fields[item].options
      ? this.state.fields[item].options
      : { multiline: false };
    return (
      <TextField
        autoFocus
        margin="dense"
        id={item}
        label={
          this.state.fields[item].label ? this.state.fields[item].label : item
        }
        fullWidth
        variant="standard"
        value={this.state.fields[item].value}
        required={this.state.fields[item].required}
        multiline={options.multiline}
        onChange={(e) => {
          this.state.fields[item].value = e.target.value;
          this.setState({ fields: this.state.fields });
        }}
      />
    );
  };

  hidden = (item) => {
    return (
      <input type="hidden" id={item} value={this.state.fields[item].value} />
    );
  };

  select = (item) => {
    const options = this.state.fields[item].options;
    const label = this.state.fields[item].label
      ? this.state.fields[item].label
      : item;
    return (
      <div className="UpsertDialog_Select">
        <span className="form__label">{label}</span>
        <Select
          margin="dense"
          id={item}
          value={this.state.fields[item].value || EntityPage.NULL_FILLER}
          label={label}
          fullWidth
          required={this.state.fields[item].required}
          onChange={(e) => {
            this.state.fields[item].value =
              e.target.value !== EntityPage.NULL_FILLER ? e.target.value : null;
            this.setState({ fields: this.state.fields });
          }}
        >
          {Object.entries(options).length !== 0 &&
            Object.keys(options).map((item, index) => (
              <MenuItem value={item}>{options[item]}</MenuItem>
            ))}
        </Select>
      </div>
    );
  };

  showField = (item) => {
    switch (this.state.fields[item].type) {
      case "text":
        return this.text(item);
      case "hidden":
        return this.hidden(item);
      case "select":
        return this.select(item);
      default:
    }
  };

  closeDialog = (fields = null) => {
    let postData = {};
    for (let field in fields) {
      postData[field] = fields[field].value;
    }

    store.dispatch(userActions.loading(true));
    if (this.props.id === 0) {
      userService.postWrapper(
        this.props.endpoints.add,
        postData,
        (data) => {
          this.props.loader();
        },
        (error) => {
          store.dispatch(userActions.loading(false));
          if (error !== "") {
            this.setState({ error });
            alert(error);
          }
        }
      );
    } else {
      userService.patchWrapperBody(
        `${this.props.endpoints.edit}/${this.props.id}`,
        postData,
        (data) => {
          this.props.loader();
        },
        (error) => {
          store.dispatch(userActions.loading(false));
          if (error !== "") {
            this.setState({ error });
            alert(error);
          }
        }
      );
    }
    this.props.closer();
  };

  render() {
    return (
      <Dialog open={this.props.dialogOpen}>
        <DialogTitle>{this.props.title}</DialogTitle>
        <form onSubmit={this.saveData}>
          <DialogContent>
            {Object.entries(this.state.fields).length !== 0 &&
              Object.keys(this.state.fields).map((item, index) =>
                this.showField(item)
              )}
          </DialogContent>
          <DialogActions>
            <Button type="submit">{i18next.t("btn_save")}</Button>
            <Button onClick={this.cancelData}>{i18next.t("btn_cancel")}</Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export { UpsertDialog };
