import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TextField from "@mui/material/TextField";

function TextFieldI18N(props) {
  const {
    id,
    type,
    value,
    error,
    name,
    label,
    placeholder,
    className,
    autoFocus,
    inputProps,
    multiline,
    onChange,
    onLeave,
    size,
    disabled,
    langList,
  } = props;

  const [tabIndex, setTabIndex] = React.useState(0);

  function handleChange(e) {
    onChange(e, langList[tabIndex]);
  }

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={tabIndex}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={(e, val) => setTabIndex(val)}
            aria-label="lab API tabs example"
          >
            {langList.map((item, index) => (
              <Tab label={item} value={index} />
            ))}
          </TabList>
        </Box>
      </TabContext>
      <TextField
            autoFocus={autoFocus}
            multiline={multiline}
            margin="dense"
            placeholder={label}
            id="desc"
            label={label}
            fullWidth
            variant="standard"
            value={value[langList[tabIndex]]}
            onChange={handleChange}
          />
    </Box>
  );
}

export default TextFieldI18N;
