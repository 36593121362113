import React from "react";
import "../css/style.css";
import i18next from "i18next";
import { connect } from "react-redux";
import { store } from "../_helpers";
import { userService } from "../_services";

import MemberDialog from "./MemberDialog";

import { CircularProgress } from "@material-ui/core";
import { userActions } from "../_actions";
import SponsorItem from "./SponsorItem";

class SponsorPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      conf_list: [],
      team: [],
      teamroles: [],
      openDialog: false,
      dName: "",
      dPhone: "",
      dMail: "",
      dNameI18N: {},
      dCommI18N: {},
      roles: [],
      roleDialog: false,
      roleId: 0,
      confId: 0,
      id: 0,
      spImage: "",
      imageStatus: 0,
      emailFilter: "",
      nameFilter: "",
      commentFilter: "",
    };
    store.subscribe(this.storeChange);
  }

  componentDidMount() {
    this.setState({ lang: this.props.app.lang });
    this.getTeam();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.app.lang !== this.state.lang) {
      this.setState({ lang: nextProps.app.lang });
      this.getTeam();
    }
  }

  uploadFile = (selectedFile) => {
    const data_upload = new FormData();
    data_upload.append("logo", selectedFile);

    const { id } = this.state;

    if (id == 0) {
      this.setState({ imgStatus: 1, dataImage: data_upload });
    } else {
      // this.uploadImage(data_upload,id);
      this.setState({ imgStatus: 1, dataImage: data_upload });
    }
  };

  uploadImage = (data_upload, id) => {
    const self = this;

    userService.uploadFile(
      `api/sponsor/${id}/upload`,
      data_upload,
      (data) => {
        console.log(data);
        if (data && data.data && data.data.logo)
          self.setState({ spImage: data.data.logo });
        if (self.state.imgStatus == 1) self.getTeam();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  editFilter = (name, val) => {
    if (name == "name") this.setState({ nameFilter: val });
    else if (name == "email") this.setState({ emailFilter: val });
    else if (name == "comment") this.setState({ commentFilter: val });
  };

  closeDialog = (val) => {
    console.log(val);

    const { id, imgStatus, dataImage } = this.state;

    if (val) {
      this.setState({ openDialog: false, isLoading: true });
      let self = this;

      const postData = { brandname: val.name, brandname_i18n: val.name_i18n };
      if (val.email) postData.email = val.email;

      if (val.comment) postData.comments = val.comment;
      if (val.comment_i18n) postData.comments_i18n = val.comment_i18n;

      if (id == 0) {
        userService.postWrapper(
          "api/sponsor",
          postData,
          (data) => {
            console.log(data);

            if (imgStatus == 1 && data.data > 0) {
              self.setState({ id: data.data });
              self.uploadImage(dataImage, data.data);
            } else self.getTeam();
          },
          (error) => {
            if (error != "") {
              self.setState({ error, isLoading: false });
              self.props.dispatch(userActions.set_error(error));
            }
          }
        );
      } else {
        userService.patchWrapperBody(
          `api/sponsor/${id}`,
          postData,
          (data) => {
            console.log(data);
            if (imgStatus == 1 && id > 0) {
              self.uploadImage(dataImage, id);
            } else self.getTeam();
          },
          (error) => {
            if (error != "") {
              self.setState({ error, isLoading: false });
              self.props.dispatch(userActions.set_error(error));
            }
          }
        );
      }
    } else
      this.setState({
        openDialog: false,
        isLoading: false,
        imgStatus: 0,
        spImage: "",
      });
  };

  getTeam = () => {
    let self = this;

    userService.getWrapper(
      "api/sponsor",
      (data) => {
        console.log(data);
        if (data.data) {
          self.setState({ team: [...data.data], isLoading: false });
        }
      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
          self.props.dispatch(userActions.set_error(error));
        }
      }
    );
  };

  addTeamMember = () => {
    // ()=>self.props.dispatch(appActions.setpage('new_member'))}
    this.setState({
      openDialog: true,
      id: 0,
      dName: "",
      dEmail: "",
      dComm: "",
      dNameI18N: {},
      dCommI18N: {},
      imgStatus: 0,
      spImage: "",
    });
  };

  storeChange = (e) => {};

  userEdit = (id) => {
    const { team } = this.state;

    let name = "";
    let email = "";
    let spImage = "";
    let comm = "";
    let name_i18n = {};
    let comment_i18n = {};

    for (let i = 0; i < team.length; i++) {
      if (team[i].id == id) {
        name = team[i].brandname;
        email = team[i].email;
        spImage = team[i].logo;
        comm = team[i].comments;
        name_i18n = team[i].brandname_i18n ?? {};
        comment_i18n = team[i].comments_i18n ?? {};

        if (!name_i18n["ru"] && name) {
          name_i18n = { ru: name, en: "", cn: "" };
        }

        if (!comment_i18n["ru"] && comm) {
          comment_i18n = { ru: comm, en: "", cn: "" };
        }

        break;
      }
    }

    this.setState({
      id,
      openDialog: true,
      dName: name,
      dMail: email,
      dComm: comm,
      dNameI18N: name_i18n,
      dCommI18N: comment_i18n,
      spImage,
      imgStatus: 0,
    });
  };

  delUserAction = (val) => {
    console.log(val);
    this.setState({ isLoading: true });
    const self = this;

    const reqStr = `api/sponsor/${val.id}`;

    userService.delWrapper(
      reqStr,
      (data) => {
        self.getTeam();
      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
          self.props.dispatch(userActions.set_error(error));
        }
      }
    );
  };

  render() {
    const self = this;
    const {
      isLoading,
      team,
      roles_list,
      nameFilter,
      emailFilter,
      commentFilter,
    } = this.state;
    const { classes } = this.props;

    return (
      <div className="layout">
        <div className="layout__contains">
          <h1 className="layout__title">{i18next.t("sponsors")}</h1>

          <div className="layout__btn">
            <button className="btn" onClick={self.addTeamMember}>
              {i18next.t("new_sponsor")}
            </button>
          </div>
        </div>

        <div className="tbl">
          <div className="tbl__top">
            <div className="tbl__items">
              <div className="tbl__item tbl__item--name">
                <input
                  type="text"
                  placeholder={
                    i18next.t("search_for") +
                    " " +
                    i18next.t("search_type_brand")
                  }
                  onChange={(e) => self.editFilter("name", e.target.value)}
                />
              </div>
              <div className="tbl__item tbl__item--text">
                <input
                  type="text"
                  placeholder={
                    i18next.t("search_for") +
                    " " +
                    i18next.t("search_type_email")
                  }
                  onChange={(e) => self.editFilter("email", e.target.value)}
                />
              </div>

              <div className="tbl__item tbl__item--text">
                <input
                  type="text"
                  placeholder={
                    i18next.t("search_for") +
                    " " +
                    i18next.t("search_type_comment")
                  }
                  onChange={(e) => self.editFilter("comment", e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="tbl__body">
            {team &&
              team
                .filter(
                  (e) =>
                    e.brandname
                      .toLowerCase()
                      .indexOf(nameFilter.toLowerCase()) >= 0
                )
                .filter(
                  (e) =>
                    emailFilter == "" ||
                    (e.email &&
                      e.email
                        .toLowerCase()
                        .indexOf(emailFilter.toLowerCase()) >= 0)
                )
                .filter(
                  (e) =>
                    commentFilter == "" ||
                    (e.comments &&
                      e.comments
                        .toLowerCase()
                        .indexOf(commentFilter.toLowerCase()) >= 0)
                )
                .map((item, index) => (
                  <SponsorItem
                    data={item}
                    isUser
                    userEdit={(e) => self.userEdit(e)}
                    userAction={(e) => self.userAction(e)}
                    roleAction={(e) => self.roleAction(e)}
                    delAction={(e) => self.delUserAction(e)}
                  />
                ))}
          </div>
        </div>
        <MemberDialog
          handleClose={self.closeDialog}
          label={i18next.t("sponsor")}
          title={i18next.t("sponsor")}
          id={this.state.id}
          imageStatus={this.state.imgStatus}
          image={this.state.spImage}
          dName={this.state.dName}
          dMail={this.state.dMail}
          dComm={this.state.dComm}
          dNameI18N={this.state.dNameI18N}
          dCommI18N={this.state.dCommI18N}
          uploadImage={self.uploadFile}
          dialogOpen={this.state.openDialog}
        />

        {isLoading && (
          <div className="shadow">
            <div className="shadow_circle">
              <CircularProgress />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedTeamPage = connect(mapStateToProps)(SponsorPage);
export { connectedTeamPage as SponsorPage };
