import DeleteIcon from "@material-ui/icons/Delete";
import classNames from "classnames";
import i18next from "i18next";
import React from "react";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import { ConferenceNotSelected } from "../_components/ConferenceNotSelected";
import TextAreaI18N from "../_components/TextAreaI18N";
import { serverUrl } from "../_constants";
import { store } from "../_helpers";
import { confService, userService } from "../_services";
import "../css/style.css";
import confLogo from "../img/conf_logo.png";


class ConfNavPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      addr: "",
      map_description_i18n: {},
      navigation_building_description_i18n: {},
      navigation_building_description: {},
      langList: ["ru"],
    };
    store.subscribe(this.storeChange);
  }

  loadConferenceData = (selectedConf) => {
    let langList = selectedConf.languages;

    if (!langList) langList = ["ru"];

    let oldObj = {
      name: selectedConf.name,
      format: selectedConf.type,
      comment: selectedConf.description,
      streamUrl: selectedConf.navigation_description,
      id: selectedConf.id,
      image: selectedConf.image,
      building_id: selectedConf.building_id,
      start_time: selectedConf.start_time,
      end_time: selectedConf.end_time,
      selectedBldId: selectedConf.building_id,
      wifi: selectedConf.wifi,
      map_description: selectedConf.map_description,
      map_image: selectedConf.map_image,
      map_link: selectedConf.map_link,
      navigation_building_description:
        selectedConf.navigation_building_description,
      navigation_building_image: selectedConf.navigation_building_image,
      navigation_description: selectedConf.navigation_description,
      navigation_image: selectedConf.navigation_image,
      langList: langList,
    };

    this.setState(oldObj);

    this.getBuildings();
    this.getConfInfo(selectedConf.id);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedConf } = this.props.user;

    if (selectedConf != prevProps.user.selectedConf) {
      this.loadConferenceData(selectedConf);
    }
  }

  componentDidMount() {
    const { selectedConf } = this.props.user;

    this.setState({ lang: this.props.app.lang });

    if (selectedConf && selectedConf.id && selectedConf.id > 0) {
      this.loadConferenceData(selectedConf);
    }
  }

  componentWillReceiveProps(nextProps) {
    //this.setState({ lang: this.props.app.lang });
    if (nextProps.app.lang !== this.state.lang) {
      this.setState({ lang: nextProps.app.lang });
      const { selectedConf } = this.props.user;
      if (selectedConf && selectedConf.id && selectedConf.id > 0) {
        this.loadConferenceData(selectedConf);
      }
    }
  }

  handleChangeI18N = (event, tabName) => {
    let tmpObj = this.state[event.target.name];
    tmpObj[tabName] = event.target.value;

    this.setState({ [event.target.name]: tmpObj });
  };

  setYandexData = (e) => {};

  getConfInfo = (id) => {
    const self = this;
    const { langList } = this.state;

    confService.confInfo(
      id,
      (data) => {
        if (data.status === 200) {
          const selectedConf = { ...data.data };

          let tmpObj = {};
          let field_list = [
            "navigation_description",
            "map_description",
            "navigation_building_description",
          ];

          for (let item of field_list) {
            tmpObj[item + "_i18n"] = selectedConf[item + "_i18n"];
          }

          for (let key of Object.keys(tmpObj)) {
            if (!tmpObj[key]) {
              let new_key = key.replace("_i18n", "");

              tmpObj[key] = {};

              for (let item in this.state.allLang) {
                if (langList.includes(item)) {
                  tmpObj[key][item] = selectedConf[new_key];
                } else {
                  tmpObj[key][item] = "";
                }
              }
            }
          }

          let oldObj = {
            name: selectedConf.name,
            format: selectedConf.type,
            comment: selectedConf.description,
            streamUrl: selectedConf.navigation_description,
            id: selectedConf.id,
            image: selectedConf.image,
            building_id: selectedConf.building_id,
            start_time: selectedConf.start_time,
            end_time: selectedConf.end_time,
            selectedBldId: selectedConf.building_id,
            wifi: selectedConf.wifi,
            map_description: selectedConf.map_description,
            map_image: selectedConf.map_image,
            map_link: selectedConf.map_link,
            navigation_building_description:
              selectedConf.navigation_building_description,
            navigation_building_image: selectedConf.navigation_building_image,
            navigation_description: selectedConf.navigation_description,
            navigation_image: selectedConf.navigation_image,
            langList: selectedConf.languages ?? ["ru"],
          };

          for (let field of field_list)
            oldObj[field + "_i18n"] = tmpObj[field + "_i18n"];

          if (!oldObj.map_description_i18n["ru"] && oldObj.map_description) {
            oldObj.map_description_i18n = {
              ru: oldObj.map_description,
              en: "",
              cn: "",
            };
          }

          if (
            !oldObj.navigation_description_i18n["ru"] &&
            oldObj.navigation_description_i18n
          ) {
            oldObj.navigation_description_i18n = {
              ru: oldObj.navigation_description,
              en: "",
              cn: "",
            };
          }

          if (
            !oldObj.navigation_building_description_i18n["ru"] &&
            oldObj.navigation_building_description
          ) {
            oldObj.navigation_building_description_i18n = {
              ru: oldObj.navigation_building_description,
              en: "",
              cn: "",
            };
          }

          self.setState(oldObj);
        }
      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
          alert(error);
        }
      }
    );
  };

  getBuildings = () => {
    let self = this;

    userService.getWrapper(
      "api/building",
      (data) => {
        let addr = "";
        let blds = [];

        if (data.data) {
          blds = [...data.data];

          for (let i = 0; i < blds.length; i++) {
            if (blds[i].id == self.state.building_id) {
              addr = blds[i].address;
              break;
            }
          }

          self.setState({ blds, addr });
        }
      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
          alert(error);
        }
      }
    );
  };

  storeChange = (e) => {};

  delImage = (tp) => {
    this.uploadFile(null, tp);
  };

  onSave = (tp) => {
    const self = this;
    const {
      id,
      navigation_description,
      navigation_building_description,
      map_description,
      map_link,
      navigation_description_i18n,
      map_description_i18n,
      navigation_building_description_i18n,
    } = this.state;

    // api/conference/1?
    const post_data = {};

    if (tp === 1) {
      post_data.navigation_description = navigation_description;
      post_data.navigation_description_i18n = navigation_description_i18n;
    }

    if (tp === 0) {
      post_data.map_description = map_description;
      post_data.map_description_i18n = map_description_i18n;
      post_data.map_link = map_link;
    }

    if (tp == 2) {
      post_data.navigation_building_description =
        navigation_building_description;
      post_data.navigation_building_description_i18n =
        navigation_building_description_i18n;
    }

    userService.patchWrapperBody(
      `api/conference/${id}`,
      post_data,
      (data) => {},
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
          alert(error);
        }
      }
    );
  };

  onMapLinkChange = (e) => {
    let sc_text = e.target.value;


    if (sc_text.indexOf("<iframe") == 0) {
      sc_text = sc_text.substr(13);
      sc_text = sc_text.split('"')[0];
      this.setState({ map_link: sc_text });
    } else this.setState({ map_link: "" });
  };

  onTextChange = (e, tp) => {
    if (tp === 1) this.setState({ navigation_description: e.target.value });

    if (tp === 0) this.setState({ map_description: e.target.value });

    if (tp === 2)
      this.setState({ navigation_building_description: e.target.value });
  };

  onDrop = (acceptedFiles, rejectedFiles, tp) => {
    if (acceptedFiles.length > 0) {
      this.uploadFile(acceptedFiles[0], tp);
    }
  };

  uploadFile = (file, tp) => {
    const data_upload = new FormData();

    if (tp === 0) data_upload.append("navigation_image", file);

    if (tp === 1) data_upload.append("map_image", file);

    if (tp === 2) data_upload.append("navigation_building_image", file);

    const self = this;

    userService.uploadFile(
      `api/conference/${this.state.id}/upload`,
      data_upload,
      (data) => {
        if (tp === 0 && data && data.data)
          self.setState({ navigation_image: data.data.navigation_image });
        if (tp === 1 && data && data.data)
          self.setState({ map_image: data.data.map_image });
        if (tp === 2 && data && data.data)
          self.setState({
            navigation_building_image: data.data.navigation_building_image,
          });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  render() {
    const self = this;
    const {
      id,
      isLoading,
      image,
      name,
      start_time,
      end_time,
      addr,
      navigation_building_description,
      navigation_description,
      map_description,
      map_image,
      map_link,
      navigation_building_image,
      navigation_image,
      wifi,
      langList,
      map_description_i18n,
      navigation_description_i18n,
      navigation_building_description_i18n,
    } = this.state;

    return (
      <div className="layout">
        {!id && <ConferenceNotSelected page={"nav"} />}
        {id > 0 && (
          <div>
            <div className="tabs-top">
              <ul className="tabs-setting-conf-contents">
                <li>
                  <div className="conf-detail">
                    <div className="conf-img">
                      <img src={image ? serverUrl + image : confLogo} alt="" />
                    </div>
                    <div className="conf-info">
                      <div className="title h1">{name}</div>
                      <div className="meta">
                        <div className="date">
                          <div className="day">
                            <span>
                              {confService.getConfDateTimeStr(
                                start_time,
                                end_time
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="address">
                          <span>{addr}</span>
                        </div>
                      </div>
                    </div>
                    <div className="conf-links"></div>
                  </div>
                </li>
              </ul>
            </div>

            <div>
              <div className="header-block">
                <div className="title">{i18next.t("nav")}</div>
              </div>

              <div className="content-block">
                <div className="settings-block">
                  <div className="item">
                    <div className="title">{i18next.t("nav_map_site")}</div>
                    <div className="edit-btns">
                      <DeleteIcon
                        onClick={() => this.setState({ map_link: "" })}
                      />
                    </div>

                    <div className="sub-title">{i18next.t("map_script")}</div>
                    <input
                      name=""
                      placeholder={i18next.t("map_code")}
                      onChange={(e) => this.onMapLinkChange(e)}
                      value={
                        map_link
                          ? '<iframe src="' +
                            map_link +
                            '" width="100%" height="100%" frameborder="0"></iframe>'
                          : ""
                      }
                    />
                    <div style={{ height: 400 }}>
                      <iframe
                        src={map_link}
                        width="100%"
                        height="100%"
                        frameborder="1"
                      ></iframe>
                    </div>

                    <div className="sub-title">{i18next.t("dialog_desc")}</div>

                    <TextAreaI18N
                      name="map_description_i18n"
                      placeholder={i18next.t("text")}
                      onChange={this.handleChangeI18N}
                      value={map_description_i18n ?? {}}
                      langList={langList}
                    />

                    <div className="layout__block-btn">
                      <button
                        className="btn"
                        type="submit"
                        onClick={() => this.onSave(0)}
                      >
                        {i18next.t("btn_save")}
                      </button>
                    </div>
                  </div>
                  <div className="item">
                    <div className="title">{i18next.t("nav_bld")}</div>
                    <div className="edit-btns">
                      <DeleteIcon onClick={() => this.delImage(1)} />
                    </div>
                    <div style={{ height: 482 }}>
                      <Dropzone onDrop={(e1, e2) => this.onDrop(e1, e2, 0)}>
                        {({ getRootProps, getInputProps, isDragActive }) => (
                          <div
                            class="file-lable"
                            {...getRootProps()}
                            className={classNames("dropzone", {
                              "dropzone--isActive": isDragActive,
                            })}
                          >
                            <input {...getInputProps()} />
                            <div class="file-label">
                              <div class="file-label_info">
                                <span>{i18next.t("select_file")}</span>
                              </div>
                              {navigation_image && (
                                <img
                                  src={
                                    navigation_image
                                      ? serverUrl + navigation_image
                                      : ""
                                  }
                                  alt=""
                                />
                              )}
                              <span class="desc-file">
                                {i18next.t("or_drag_image")}
                              </span>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>

                    <div className="sub-title">{i18next.t("dialog_desc")}</div>

                    <TextAreaI18N
                      name="navigation_description_i18n"
                      placeholder={i18next.t("text")}
                      onChange={this.handleChangeI18N}
                      value={navigation_description_i18n ?? {}}
                      langList={langList}
                    />

                    <div className="layout__block-btn">
                      <button
                        className="btn"
                        type="submit"
                        onClick={() => this.onSave(1)}
                      >
                        {i18next.t("btn_save")}
                      </button>
                    </div>
                  </div>

                  <div className="item">
                    <div className="title">{i18next.t("nav_exposition")}</div>
                    <div className="edit-btns">
                      <DeleteIcon onClick={() => this.delImage(2)} />
                    </div>
                    <div style={{ height: 482 }}>
                      <Dropzone onDrop={(e1, e2) => this.onDrop(e1, e2, 2)}>
                        {({ getRootProps, getInputProps, isDragActive }) => (
                          <div
                            class="file-lable"
                            {...getRootProps()}
                            className={classNames("dropzone", {
                              "dropzone--isActive": isDragActive,
                            })}
                          >
                            <input {...getInputProps()} />
                            <div class="file-label">
                              <div class="file-label_info">
                                <span>{i18next.t("select_file")}</span>
                              </div>
                              {navigation_building_image && (
                                <img
                                  src={
                                    navigation_building_image
                                      ? serverUrl + navigation_building_image
                                      : ""
                                  }
                                  alt=""
                                />
                              )}
                              <span class="desc-file">
                                {i18next.t("or_drag_image")}
                              </span>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                    <div className="sub-title">{i18next.t("dialog_desc")}</div>

                    <TextAreaI18N
                      name="navigation_building_description_i18n"
                      placeholder={i18next.t("text")}
                      onChange={this.handleChangeI18N}
                      value={navigation_building_description_i18n ?? {}}
                      langList={langList}
                    />
                    <div className="layout__block-btn">
                      <button
                        className="btn"
                        type="submit"
                        onClick={() => this.onSave(2)}
                      >
                        {i18next.t("btn_save")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedConfNavPage = connect(mapStateToProps)(ConfNavPage);
export { connectedConfNavPage as ConfNavPage };

