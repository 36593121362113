import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { confService } from "../_services";

export default function MemberDialog(props) {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [city, setCity] = React.useState("");
  const [count, setCount] = React.useState(0);

  const {
    handleClose,
    label,
    title,
    dialogOpen,
    dName,
    dPhone,
    dMail,
    dCity,
    id,
  } = props;

  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    if (count == 0) {
      setPhone(dPhone);
      setName(dName);
      setCity(dCity);
      setEmail("");
    }
  });

  function saveData(e) {
    e.preventDefault();

    if (id == 0) {
      if (name != "" && email != "") {
        // && city!='' && phone!=''

        if (confService.validateEmail(email)) {
          handleClose({
            name,
            email,
            phone,
            city,
          });
          setCount(0);
        } else {
          alert(t("enter_correct_email"));
        }
      } else {
        alert(t("fill_name_and_email"));
      }
    } else if (name != "" && id > 0) {
      handleClose({ name, phone, city });
      setCount(0);
    }
  }

  function cancelData() {
    handleClose("");
    setCount(0);
  }

  return (
    <Dialog open={dialogOpen}>
      <DialogTitle>{title}</DialogTitle>
      <form onSubmit={saveData}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={t("dialog_name")}
            fullWidth
            variant="standard"
            value={name}
            required
            onChange={(e) => {
              setName(e.target.value);
              setCount(count + 1);
            }}
          />
          {id == 0 && (
            <TextField
              margin="dense"
              id="email"
              label={t("dialog_email")}
              fullWidth
              variant="standard"
              value={email}
              required
              onChange={(e) => {
                setEmail(e.target.value);
                setCount(count + 1);
              }}
            />
          )}
          <TextField
            margin="dense"
            id="phone"
            label={t("dialog_phone")}
            fullWidth
            variant="standard"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              setCount(count + 1);
            }}
          />
          <TextField
            margin="dense"
            id="city"
            label={t("dialog_city")}
            fullWidth
            variant="standard"
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
              setCount(count + 1);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">{t("btn_save")}</Button>
          <Button onClick={cancelData}>{t("btn_cancel")}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
