import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import TextFieldI18N from "./TextFieldI18N";
import { useTranslation } from "react-i18next";

const LANG_LIST = ["ru", "en", "cn"];

export default function EditDialog(props) {
  const {
    handleClose,
    label,
    nameLabel,
    title,
    oldVal,
    dialogOpen,
    dialogType,
    fileStatus,
    onFileUpload,
    isSlotService,
  } = props;

  const {t} = useTranslation();

  const [count, setCount] = React.useState(0);
  const [value, setValue] = React.useState(oldVal ? oldVal.name : "");
  const [editNameDisabled, setEditNameDisabled] = React.useState(false);

  const [description, setDesc] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [city, setCity] = React.useState("");
  const [comm, setComm] = React.useState("");
  const [name_i18n, setNameI18N] = React.useState({});
  const [description_i18n, setDescI18N] = React.useState({});
  const [city_i18n, setCityI18N] = React.useState({});
  const [comment_i18n, setCommI18N] = React.useState({});

  const [isServiceSlot, setServiceSlot] = React.useState(false);

  React.useEffect(() => {
    if (count == 0) {
      setValue(oldVal ? oldVal.name : "");
      setNameI18N(oldVal && oldVal.name_i18n ? oldVal.name_i18n : {});

      if (dialogType == "speaker") {
        console.log(oldVal);
        setDesc(oldVal.description ? oldVal.description : "");
        setCity(oldVal.city ? oldVal.city : "");
        setPhone(oldVal.phone ? oldVal.phone : "");
        setComm(oldVal.comment ? oldVal.comment : "");
        setEmail(oldVal.email ? oldVal.email : "");
        if (oldVal.description_i18n) setDescI18N(oldVal.description_i18n);

        if (oldVal.city_i18n) setCityI18N(oldVal.city_i18n);

        if (oldVal.comment_i18n) setCommI18N(oldVal.comment_i18n);
      } else if (dialogType == "theme") {
        if (oldVal) {
          if (oldVal.slot_type == "lesson") {
            
            setDesc(oldVal.entity ? oldVal.entity.description : "");
            setValue(oldVal.entity ? oldVal.entity.name : "");
            setNameI18N(oldVal.entity ? oldVal.entity.name_i18n : {});
            setDescI18N(oldVal.entity ? oldVal.entity.description_i18n : {});
          } else setDesc(oldVal.description ? oldVal.description : "");

          if (oldVal.slot_type == "service_lesson") {
            setValue(oldVal.entity ? oldVal.entity.name : "");
            setEditNameDisabled(true);
          }

          setServiceSlot(oldVal.isSlotService ? true : false);
        }
      }
    }
  }, [oldVal, dialogType, count]);

  function editVal(e) {
    setValue(e.target.value);
    setCount(count + 1);
  }

  function saveTheme() {
    if (value != "") {
      const tmpObj = { name: value, name_i18n: name_i18n };

      if (dialogType == "speaker") {
        tmpObj.description = description;
        tmpObj.description_i18n = description_i18n;
        tmpObj.phone = phone;
        tmpObj.city = city;
        tmpObj.comment = comm;
        tmpObj.email = email;
        tmpObj.name_i18n = name_i18n;
        tmpObj.city_i18n = city_i18n;
        tmpObj.comment_i18n = comment_i18n;
      } else if (dialogType == "theme" && !isSlotService) {
        tmpObj.description = description;
        tmpObj.description_i18n = description_i18n;
      }

      handleClose(tmpObj);
      setCount(0);
      setValue("");
      setValue("");
      setPhone("");
      setDesc("");
      setEmail("");
      setComm("");
      setCity("");
      setNameI18N({});
      setDescI18N({});
      setCityI18N({});
      setCommI18N({});
      setEditNameDisabled(false);
    }
  }

  function cancelTheme() {
    handleClose(null);
    setCount(0);
    setValue("");
    setValue("");
    setPhone("");
    setDesc("");
    setEmail("");
    setComm("");
    setCity("");
    setNameI18N({});
    setDescI18N({});
    setCityI18N({});
    setCommI18N({});
    setEditNameDisabled(false);
  }

  function onDrop(acceptedFiles, rejectedFiles) {
    if (acceptedFiles.length > 0) {
      onFileUpload(acceptedFiles[0]);
    }
  }

  return (
    <Dialog open={dialogOpen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextFieldI18N
          autoFocus={true}
          margin="dense"
          id="name"
          label={nameLabel}
          fullWidth
          variant="standard"
          disabled={editNameDisabled}
          value={name_i18n ?? {}}
          onChange={(e, tabName) => {
            let tmpI18N = name_i18n;
            tmpI18N[tabName] = e.target.value;

            setNameI18N(tmpI18N);
            setValue(tmpI18N["ru"]);
            setCount(count + 1);
          }}
          langList={LANG_LIST}
        />
        {(dialogType == "speaker" ||
          (dialogType == "theme" && !isSlotService)) && (
          <TextFieldI18N
            margin="dense"
            id="desc"
            label={t("dialog_desc")}
            fullWidth
            variant="standard"
            multiline
            value={description_i18n ?? {}}
            onChange={(e, tabName) => {
              let tmpI18N = description_i18n;
              if (!tmpI18N)
                tmpI18N = {'ru':'','en':'','cn':''}

              tmpI18N[tabName] = e.target.value;

              setDescI18N(tmpI18N);
              setDesc(tmpI18N["ru"]);
              setCount(count + 1);
            }}
            langList={LANG_LIST}
          />
        )}
        {dialogType == "speaker" && (
          <TextField
            margin="dense"
            id="email"
            label={t("dialog_email")}
            fullWidth
            variant="standard"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setCount(count + 1);
            }}
          />
        )}
        {dialogType == "speaker" && (
          <TextField
            margin="dense"
            id="phone"
            label={t("dialog_phone")}
            fullWidth
            variant="standard"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              setCount(count + 1);
            }}
          />
        )}
        {dialogType == "speaker" && (
          <TextFieldI18N
            margin="dense"
            id="phone"
            label={t("dialog_city")}
            fullWidth
            variant="standard"
            value={city_i18n}
            onChange={(e, tabName) => {
              let tmpI18N = city_i18n;
              tmpI18N[tabName] = e.target.value;

              setCityI18N(tmpI18N);
              setCity(tmpI18N["ru"]);
              setCount(count + 1);
            }}
            langList={LANG_LIST}
          />
        )}
        {dialogType == "speaker" && (
          <TextFieldI18N
            margin="dense"
            id="comm"
            label={t("dialog_comment")}
            fullWidth
            variant="standard"
            multiline
            value={comment_i18n}
            onChange={(e, tabName) => {
              let tmpI18N = comment_i18n;
              tmpI18N[tabName] = e.target.value;

              setCommI18N(tmpI18N);
              setComm(tmpI18N["ru"]);
              setCount(count + 1);
            }}
            langList={LANG_LIST}
          />
        )}

        {dialogType == "theme" &&
          oldVal.slot_type !== "service_lesson" &&
          !isSlotService && (
            <div style={{ marginTop: 10 }}>
              <div style={{ paddingBottom: 5 }}>{t("presentation_file")}</div>
              <Dropzone onDrop={onDrop} class="visually--hidden">
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div
                    style={{
                      textAlign: "center",
                      verticalAlign: "center",
                      width: 400,
                      height: 50,
                      background: "#36B2F1",
                      borderRadius: 5,
                    }}
                    {...getRootProps()}
                    className={classNames("dropzone", {
                      "dropzone--isActive": isDragActive,
                    })}
                  >
                    <input {...getInputProps()} />

                    {fileStatus == 0 &&
                      (isDragActive ? (
                        <p style={{ fontSize: 12 }}>{t("dropzone_file_upload")}</p>
                      ) : (
                        <p
                          style={{ fontSize: 12, padding: 10, borderRadius: 5 }}
                        >
                         {t("dropzone_title_hint")}
                        </p>
                      ))}
                    {fileStatus == 1 && (
                      <p style={{ fontSize: 12, padding: 10 }}>{t("dropzone_file_uploaded")}</p>
                    )}
                  </div>
                )}
              </Dropzone>
            </div>
          )}
      </DialogContent>
      <DialogActions>
        <Button onClick={saveTheme}>{t("btn_save")}</Button>
        <Button onClick={cancelTheme}>{t("btn_cancel")}</Button>
      </DialogActions>
    </Dialog>
  );
}
