import React from "react";
import "../css/style.css";

export default function TeamItem(props) {
	
	
  return (
    <div className="tbl__body-row">
      <div className="tbl__items">
        <div className="tbl__item tbl__item--city">
            <span className="profile__name">
              {props.data ? props.data.user_name : ""}
            </span>
          
        </div>
		<div className="tbl__item tbl__item--city">
          <span>{props.data ? props.data.conf_name : ""}</span>
        </div>
        

        <div className="tbl__item tbl__item--text">
          <span>{props.data ? props.data.ticket_name : ""}</span>
        </div>
        <div className="tbl__item tbl__item--text">
          <span>
            {props.data ? props.data.updated_at.replace(".000000Z", "") : ""}
          </span>
        </div>

      </div>
    </div>
  );
}
