import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import "../css/style.css";
import { connect } from "react-redux";
import i18next from "i18next";
import { history } from "../_helpers";
import { appActions } from "../_actions";
import personLogo from "../img/person.png";
// import Popover from '@mui/material/Popover';
import allconfLogo from "../img/allconf2.png";
import scheduleLogo from "../img/schedule.png";
import ncLogo from "../img/logo.png";
import pollLogo from "../img/poll_chart_white.png";

const ACTIVE_COLOR = "#0c2441";

function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1")}=([^;]*)`
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setCookie(name, value, options) {
  options = options || {};
  let { expires } = options;
  if (typeof expires === "number" && expires) {
    const d = new Date();
    d.setTime(d.getTime() + expires * 1000);
    expires = options.expires = d;
  }

  if (expires && expires.toUTCString) options.expires = expires.toUTCString();

  value = encodeURIComponent(value);
  let updatedCookie = `${name}=${value}`;
  for (const propName in options) {
    updatedCookie += `; ${propName}`;
    const propValue = options[propName];
    if (propValue !== true) {
      updatedCookie += `=${propValue}`;
    }
  }

  document.cookie = updatedCookie;
}

function setStorage(name, val) {
  localStorage.setItem(name, val);
}

class HeadMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { anchorEl: null };
  }

  componentDidMount() {
    this.setState({ isMobile: window.screen.width < 600 });
  }

  changeLang = () => {
    let { lang } = this.props.app;
    if (lang == "ru") lang = "en";
    else if (lang == "en") lang = "cn";
    else lang = "ru";

    i18next.init({
      lng: lang,
      resources: require(`../_lang/${lang}.json`),
    });

    localStorage.setItem("navic_lang", lang);
    this.props.dispatch(appActions.setlang(lang));
  };

  onLogout = () => {
    setStorage("navic_user", "");
    setStorage("token", "");
    localStorage.setItem("conf", '{"id":0}');
    localStorage.setItem("user", null);

    history.push("/login");
  };

  handleClose = (name) => {
    let contacts = "support@naviconf.com";

    this.setState({ anchorEl: null });
    if (name == "confs" || name == "tickets") this.props.menuPress(name);

    //window.location.href = 'https://naviconf.com/poll-qf07q4yhf1q40879hfq12.htm';
    //window.open('https://naviconf.com/poll-qf07q4yhf1q40879hfq12.htm');

    if (name == "vote")
      window.location.href =
        "https://events.mgusit.ru/votes/1?key=646f53d5ad92d4381ff7dc5aafae79a8ef42c0d97777c80f1ea0de2f6e33afed";

    if (name == "mail") window.open(`mailto:${contacts}`);

    if (name == "exit") this.onLogout();
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  render() {
    const self = this;
    const { lang } = this.props.app;
    const { anchorEl, isMobile } = this.state;

    return (
      <div>
        <ul
          className="header__blocks"
          style={{ border: "1px solid " + ACTIVE_COLOR }}
        >
          <li className="header__block header__form">
            {this.props.type == "student" && (
              <div>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                >
                  <MenuIcon size="large" />
                  <span style={{ paddingLeft: 10, fontSize: 20 }}>
                    {i18next.t("menu")}
                  </span>
                </IconButton>

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={this.handleClose}
                >
                  <MenuItem
                    onClick={() => this.handleClose("")}
                    style={{
                      backgroundColor: ACTIVE_COLOR,
                      paddingLeft: 20,
                      paddingTop: 20,
                      fontSize: 30,
                      color: "white",
                    }}
                  >
                    <div>
                      <img src={ncLogo} />

                      <div onClick={self.changeLang} style={{ marginTop: 10, marginLeft: 5 }}>
                        <span>{i18next.t("lang_" + lang)}</span>
                      </div>
                    </div>
                  </MenuItem>
                  <MenuItem
                    onClick={() => this.handleClose("confs")}
                    style={{
                      backgroundColor: ACTIVE_COLOR,
                      paddingLeft: 20,
                      paddingTop: 20,
                      fontSize: 30,
                      color: "white",
                    }}
                  >
                    <img
                      src={allconfLogo}
                      style={{ marginRight: 10, width: 40, height: 40 }}
                    />
                    <span>{i18next.t("confs_title")}</span>
                  </MenuItem>
                  <MenuItem
                    onClick={() => this.handleClose("tickets")}
                    style={{
                      backgroundColor: ACTIVE_COLOR,
                      paddingLeft: 20,
                      paddingTop: 20,
                      paddingBottom: 20,
                      fontSize: 30,
                      color: "white",
                    }}
                  >
                    <img
                      src={scheduleLogo}
                      style={{ marginRight: 10, width: 40, height: 40 }}
                    />
                    <span>{i18next.t("tickets")}</span>
                  </MenuItem>

                  <MenuItem
                    onClick={() => this.handleClose("vote")}
                    style={{
                      backgroundColor: ACTIVE_COLOR,
                      paddingLeft: 20,
                      paddingTop: 20,
                      paddingBottom: 20,
                      fontSize: 30,
                      color: "white",
                    }}
                  >
                    <span>{i18next.t("votes")}</span>
                  </MenuItem>

                  <MenuItem
                    onClick={() => this.handleClose("mail")}
                    style={{
                      backgroundColor: ACTIVE_COLOR,
                      paddingLeft: 20,
                      paddingTop: 20,
                      paddingBottom: 20,
                      fontSize: 30,
                      color: "white",
                    }}
                  >
                    <span>{i18next.t("support")}</span>
                  </MenuItem>
                  <MenuItem
                    onClick={() => this.handleClose("exit")}
                    style={{
                      backgroundColor: ACTIVE_COLOR,
                      paddingLeft: 20,
                      paddingTop: 20,
                      paddingBottom: 20,
                      fontSize: 30,
                      color: "white",
                    }}
                  >
                    <span>{i18next.t("exit")}</span>
                  </MenuItem>
                </Menu>
              </div>
            )}
          </li>

          <li className="header__block">
            <a href="#" className="profile">
              <img src={personLogo} alt="" className="profile__img" />
              <span className="profile__name">
                {self.props.name ? self.props.name : self.props.email}
              </span>
            </a>
          </li>

          {!isMobile && (
            <li className="header__block">
              <div className="block-dropdown">
                <button className="block-dropdwon__btn">
                  {self.props.type == "org" && (
                    <span>{i18next.t("type_org")}</span>
                  )}
                  {self.props.type == "student" && (
                    <span>{i18next.t("type_visitor")}</span>
                  )}
                  {self.props.type == "team_member" && (
                    <span>{i18next.t("type_member")}</span>
                  )}
                  <svg
                    className="block-dropdwon__icon"
                    width="10"
                    height="7"
                    fill="none"
                  >
                    <use href="./img/sprite.svg#dropdown-icon" />
                  </svg>
                </button>
              </div>
            </li>
          )}

          {!isMobile && (
            <li className="header__block">
              <div className="lang">
                <button className="lang__btn" onClick={self.changeLang}>
                  <img src="./img/lang_ru.png" alt="" className="lang__flag" />
                  <span>{lang}</span>
                  <svg className="lang__icon" width="10" height="7" fill="none">
                    <use href="./img/sprite.svg#dropdown-icon" />
                  </svg>
                </button>
              </div>
            </li>
          )}

          {(!isMobile || self.props.type != "student") && (
            <li className="header__block">
              <div className="lang">
                <button className="block-dropdwon__btn" onClick={self.onLogout}>
                  <span>{i18next.t("exit")}</span>
                </button>
              </div>
            </li>
          )}
        </ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;
  // const {user} = authentication;
  const { unresolvedAppealCount } = app;
  return {
    user,
    app,
    authentication,
  };
}

const connectedHeadMenu = connect(mapStateToProps)(HeadMenu);
export { connectedHeadMenu as HeadMenu };
