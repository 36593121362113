import React from "react";
import { store } from "../_helpers";
import "../css/style.css";

import { connect } from "react-redux";

import MemberDialog from "./MemberDialog";

import { CircularProgress } from "@material-ui/core";
import { userActions } from "../_actions";
import { userService } from "../_services";
import SpeakerItem from "./SpeakerItem";
import i18next from "i18next";

const field_list = ["name", "city", "description", "comment"];

class SpeakersPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      conf_list: [],
      team: [],
      teamroles: [],
      openDialog: false,
      dName: "",
      dPhone: "",
      dMail: "",
      dCity: "",
      dDesc: "",
      dComm: "",
      dNameI18N: {},
      dCityI18N: {},
      dDescI18N: {},
      dCommI18N: {},
      roles: [],
      roleDialog: false,
      roleId: 0,
      confId: 0,
      id: 0,
      conf_id: 0,
      phoneFilter: "",
      nameFilter: "",
      cityFilter: "",
      descFilter: "",
      emailFilter: "",
      commentFilter: "",
      allChecked: false,
      langList: ["ru", "en", "cn"],
      allLang: { ru: i18next.t('lang_ru'), en: i18next.t("lang_en"), cn: i18next.t("lang_cn")},
    };
    store.subscribe(this.storeChange);
  }

  componentDidMount() {
    // this.props.dispatch(userActions.teamorg());

    const conf_list = [];
    this.setState({ lang: this.props.app.lang });
    let self = this;
    // this.getRole();
    this.getTeam();
    this.getConfs();
  }

  componentWillReceiveProps(nextProps) {
    //this.setState({ lang: this.props.app.lang });
    if (nextProps.app.lang !== this.state.lang) {
      this.setState({ lang: nextProps.app.lang });
      this.getTeam();
      this.getConfs();
    }
  }

  getConfs = () => {
    const self = this;
    userService.getWrapper(
      "api/conference",
      (data) => {
        if (data.status == 200) {
          self.setState({
            conf_list: data.data.filter((e) => e.contracts_for_speakers == 0),
          });
        }
      },
      (error) => {}
    );
  };

  uploadFile = (selectedFile) => {
    const data_upload = new FormData();
    data_upload.append("photo", selectedFile);

    const { id } = this.state;

    if (id == 0) {
      this.setState({ imgStatus: 1, dataImage: data_upload });
    } else {
      // this.uploadImage(data_upload,id);
      this.setState({ imgStatus: 1, dataImage: data_upload });
    }
  };

  uploadImage = (data_upload, id) => {
    const self = this;

    console.log(data_upload);

    userService.uploadFile(
      `api/speaker/${id}/upload`,
      data_upload,
      (data) => {
        console.log(data);

        if (data && data.data && data.data.photo)
          self.setState({ spImage: data.data.photo });
        if (self.state.imgStatus == 1) self.getTeam();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  editFilter = (name, val) => {
    if (name == "name") this.setState({ nameFilter: val });
    else if (name == "desc") this.setState({ descFilter: val });
    else if (name == "city") this.setState({ cityFilter: val });
    else if (name == "email") this.setState({ emailFilter: val });
    else if (name == "phone") this.setState({ phoneFilter: val });
    else if (name == "comment") this.setState({ commentFilter: val });
  };

  closeDialog = (val) => {
    const { id, imgStatus, dataImage } = this.state;

    if (val) {
      this.setState({ openDialog: false, isLoading: true });
      let self = this;

      const postData = { ...val };
      if (id == 0) {
        Object.keys(postData).forEach((key) => {
          if (postData[key] == null || postData[key] == "")
            delete postData[key];
        });

        userService.postWrapper(
          "api/speaker",
          postData,
          (data) => {
            if (imgStatus == 1 && data.data > 0) {
              self.setState({ id: data.data });
              self.uploadImage(dataImage, data.data);
            } else self.getTeam();
          },
          (error) => {
            if (error != "") {
              self.setState({ error, isLoading: false });
              self.props.dispatch(userActions.set_error(error));
            }
          }
        );
      } else {
        userService.patchWrapperBody(
          `api/speaker/${id}`,
          postData,
          (data) => {
            if (imgStatus == 1 && id > 0) {
              self.uploadImage(dataImage, id);
            } else self.getTeam();
          },
          (error) => {
            if (error != "") {
              self.setState({ error, isLoading: false });
              self.props.dispatch(userActions.set_error(error));
            }
          }
        );
      }
    } else
      this.setState({
        openDialog: false,
        isLoading: false,
        imgStatus: 0,
        spImage: "",
      });
  };

  getTeam = () => {
    let self = this;

    const { langList } = this.state;

    userService.getWrapper(
      "api/speaker",
      (data) => {
        if (data.data) {
          const newData = data.data.map((e) => ({ ...e, checked: false }));

          for (let i = 0; i < newData.length; i++) {
            let teamItem = { ...newData[i] };

            let tmpObj = {};

            for (let item of field_list) {
              tmpObj[item + "_i18n"] = teamItem[item + "_i18n"];
            }

            for (let key of Object.keys(tmpObj)) {
              console.log(key, tmpObj[key]);

              if (!tmpObj[key]) {
                let new_key = key.replace("_i18n", "");

                tmpObj[key] = {};

                for (let item in this.state.allLang) {
                  if (langList.includes(item)) {
                    tmpObj[key][item] = teamItem[new_key];
                  } else {
                    tmpObj[key][item] = "";
                  }
                }
              }

              teamItem[key] = tmpObj[key];
            }

            newData[i] = { ...teamItem };
          }

          console.log(newData);

          self.setState({ team: [...newData], isLoading: false });
        }
      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
          self.props.dispatch(userActions.set_error(error));
        }
      }
    );
  };

  addTeamMember = () => {
    // ()=>self.props.dispatch(appActions.setpage('new_member'))}
    this.setState({
      openDialog: true,
      id: 0,
      dName: "",
      dPhone: "",
      dEmail: "",
      dDesc: "",
      dCity: "",
      dComm: "",
      dCommI18N: {},
      dNameI18N: {},
      dDescI18N: {},
      dCityI18N: {},
      imgStatus: 0,
      spImage: "",
    });
  };

  storeChange = (e) => {};

  onSpeakerCheck = (id) => {
    const { team } = this.state;

    const newTeam = team.map((e) => {
      if (e.id == id) {
        let newCheck = !e.checked;
        return { ...e, checked: newCheck };
      }
      return { ...e };
    });

    this.setState({ team: newTeam });
  };

  userEdit = (id) => {
    const { team } = this.state;

    let name = "";
    let phone = "";
    let email = "";
    let comment = "";
    let desc = "";
    let city = "";
    let spImage = "";

    let dNameI18N = {};
    let dDescI18N = {};
    let dCommentI18N = {};
    let dCityI18N = {};

    for (let i = 0; i < team.length; i++) {
      if (team[i].id == id) {
        name = team[i].name;
        phone = team[i].phone;
        email = team[i].email;
        desc = team[i].description;
        comment = team[i].comment;
        city = team[i].city;
        spImage = team[i].photo;
        dNameI18N = { ...team[i].name_i18n };
        dDescI18N = { ...team[i].description_i18n };
        dCommentI18N = { ...team[i].comment_i18n };
        dCityI18N = { ...team[i].city_i18n };
        break;
      }
    }

    let oldObj = {
      id,
      openDialog: true,
      dName: name,
      dPhone: phone,
      dCity: city,
      dComm: comment,
      dDesc: desc,
      dMail: email,
      spImage,
      imgStatus: 0,
      dNameI18N,
      dDescI18N,
      dCommentI18N,
      dCityI18N,
    };

    this.setState(oldObj);
  };

  delUser = (val) => {
    console.log(val);
    this.setState({ isLoading: true });
    const self = this;

    const reqStr = `api/speaker/${val.id}`;

    userService.delWrapper(
      reqStr,
      (data) => {
        self.getTeam();
      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
          self.props.dispatch(userActions.set_error(error));
        }
      }
    );
  };

  assignSpeakers = () => {
    const { team, conf_id } = this.state;

    if (conf_id > 0) {
      const fTeam = team.filter((e) => e.checked);
      // alert(fTeam.length);
      let spStr = "";
      for (let i = 0; i < fTeam.length; i++) spStr += `${fTeam[i].name}, `;

      if (spStr.length > 0) alert(spStr);
      else alert(i18next.t("select_speakers"));
    } else alert(i18next.t("select_conf"));
  };

  checkAll = () => {
    const { allChecked, team } = this.state;
    const newTeam = team.map((e) => ({ ...e, checked: !allChecked }));

    this.setState({ allChecked: !allChecked, team: newTeam });
  };

  render() {
    const self = this;
    const {
      isLoading,
      team,
      roles_list,
      conf_list,
      conf_id,
      nameFilter,
      descFilter,
      cityFilter,
      phoneFilter,
      emailFilter,
      commentFilter,
      allChecked,
      dName,
      dCity,
      dDesc,
      dComm,
      dPhone,
      dEmail,
      dNameI18N,
      dDescI18N,
      dCommI18N,
      dCityI18N,
    } = this.state;
    const { classes } = this.props;

    return (
      <div className="layout">
        <div className="layout__contains">
          <h1 className="layout__title">{i18next.t("speakers")}</h1>

          <div className="layout__btn">
            <button className="btn" onClick={self.addTeamMember}>
              {i18next.t("new_speaker")}
            </button>
          </div>
        </div>

        <div className="tbl">
          <div className="tbl__top">
            <div className="tbl__items">
              <div className="tbl__item tbl__item--name">
                <input
                  type="text"
                  placeholder={i18next.t("name_search")}
                  value={nameFilter}
                  onChange={(e) => self.editFilter("name", e.target.value)}
                />
              </div>

              <div className="tbl__item tbl__item--text">
                <input
                  type="text"
                  placeholder={i18next.t("desc_search")}
                  value={descFilter}
                  onChange={(e) => self.editFilter("desc", e.target.value)}
                />
              </div>

              <div className="tbl__item tbl__item--text">
                <input
                  type="text"
                  placeholder={
                    i18next.t("search_for") +
                    " " +
                    i18next.t("search_type_email")
                  }
                  value={emailFilter}
                  onChange={(e) => self.editFilter("email", e.target.value)}
                />
              </div>
              <div className="tbl__item tbl__item--text">
                <input
                  type="text"
                  placeholder={
                    i18next.t("search_for") +
                    " " +
                    i18next.t("search_type_phone")
                  }
                  value={phoneFilter}
                  onChange={(e) => self.editFilter("phone", e.target.value)}
                />
              </div>
              <div className="tbl__item tbl__item--city">
                <input
                  type="text"
                  placeholder={
                    i18next.t("search_for") +
                    " " +
                    i18next.t("search_type_city")
                  }
                  value={cityFilter}
                  onChange={(e) => self.editFilter("city", e.target.value)}
                />
              </div>

              <div className="tbl__item tbl__item--text">
                <input
                  type="text"
                  placeholder={
                    i18next.t("search_for") +
                    " " +
                    i18next.t("search_type_comment")
                  }
                  value={commentFilter}
                  onChange={(e) => self.editFilter("comment", e.target.value)}
                />
              </div>
              <div className="calendar__body--text" style={{ width: 60 }} />
            </div>
          </div>

          <div className="tbl__body">
            {team &&
              team
                .filter(
                  (e) =>
                    e.name.toLowerCase().indexOf(nameFilter.toLowerCase()) >= 0
                )
                .filter(
                  (e) =>
                    descFilter == "" ||
                    (e.description &&
                      e.description
                        .toLowerCase()
                        .indexOf(descFilter.toLowerCase()) >= 0)
                )
                .filter(
                  (e) =>
                    cityFilter == "" ||
                    (e.city &&
                      e.city.toLowerCase().indexOf(cityFilter.toLowerCase()) >=
                        0)
                )
                .filter(
                  (e) =>
                    phoneFilter == "" ||
                    (e.phone &&
                      e.phone
                        .toLowerCase()
                        .indexOf(phoneFilter.toLowerCase()) >= 0)
                )
                .filter(
                  (e) =>
                    emailFilter == "" ||
                    (e.email &&
                      e.email
                        .toLowerCase()
                        .indexOf(emailFilter.toLowerCase()) >= 0)
                )
                .filter(
                  (e) =>
                    commentFilter == "" ||
                    (e.comment &&
                      e.comment
                        .toLowerCase()
                        .indexOf(commentFilter.toLowerCase()) >= 0)
                )
                .map((item, index) => (
                  <SpeakerItem
                    data={item}
                    checked={item.checked}
                    isUser
                    onCheck={self.onSpeakerCheck}
                    userEdit={(e) => self.userEdit(e)}
                    delAction={(e) => self.delUser(e)}
                  />
                ))}
          </div>
        </div>
        <MemberDialog
          handleClose={self.closeDialog}
          label={i18next.t("speaker")}
          title={i18next.t("speaker")}
          id={this.state.id}
          dName={dName}
          dPhone={dPhone}
          dMail={dEmail}
          dComm={dComm}
          dDesc={dDesc}
          dCity={dCity}
          dNameI18N={dNameI18N}
          dCommI18N={dCommI18N}
          dDescI18N={dDescI18N}
          dCityI18N={dCityI18N}
          dialogOpen={this.state.openDialog}
          imageStatus={this.state.imgStatus}
          image={this.state.spImage}
          uploadImage={self.uploadFile}
        />

        {isLoading && (
          <div className="shadow">
            <div className="shadow_circle">
              <CircularProgress />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedSpeakersPage = connect(mapStateToProps)(SpeakersPage);
export { connectedSpeakersPage as SpeakersPage };
