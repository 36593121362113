import React from "react";
import i18next from "i18next";
import { cn as lang_cn } from '../_lang/cn.json';
import { en as lang_en } from '../_lang/en.json';
import { ru as lang_ru } from '../_lang/ru.json';
import { history } from "../_helpers";

export default class VerifyPage extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {lang:'ru'}
  }


  componentDidMount() {
    
    let lang = localStorage.getItem("navic_lang") ?? "ru";
	  this.setState({lang});
	
  }

  getI18N = (textKey) => 
  {
	  const { lang } = this.state;
	  
	  if (!lang) return;
	  
	  if (lang == 'ru')
	  {
		  if (Object.keys(lang_ru.translation).indexOf(textKey)>=0)
			return lang_ru.translation[textKey];
	  }
		
		if (lang == 'en')
			if (Object.keys(lang_en.translation).indexOf(textKey)>=0)
			return lang_en.translation[textKey];
		
		if (lang == 'cn')
			if (Object.keys(lang_cn.translation).indexOf(textKey)>=0)
			return lang_cn.translation[textKey];
	  
	  return textKey;
  }


  render() {
    return (
      <div style={{ width: "100%", height: "100%", verticalAlign: "center" }}>
        <div
          style={{
            width: 400,
            margin: "0 auto",
            paddingTop: "30vh",
            fontSize: 20,
          }}
        >
          <div>
            <div style={{ paddingBottom: 20 }}>
              {this.getI18N("registry_nc_success")}
            </div>
            <button onClick={() => history.push("/")}>
              <span
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  fontSize: 20,
                }}
              >
                {this.getI18N("move_to_profile")}
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}
