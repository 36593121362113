import MoreIcon from "@material-ui/icons/MoreHoriz";
import React from "react";
import { useTranslation, Trans } from "react-i18next";
import "../css/constructor.css";
import "../css/style.css";
import SlotItem from "./SlotItem";

import Fade from "@mui/material/Fade";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import addLogo from "../img/add.png";

export default function SectionItem(props) {
  const [isOpen, setOpen] = React.useState(true);
  const [sectName, setSectName] = React.useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  function openMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    slot,
    indexSect,
    themes,
    rooms,
    changeThemes,
    changeRoom,
    addTheme,
    sectionNameChange,
    sectionOnDelete,
    delSlot,
    unbindSlot,
    addSpeaker,
    delSpeaker,
    changeSpeakers,
    speakers,
    sectUp,
    sectDown,
    editSectionName,
    plusDuration,
    minusDuration,
    editTheme,
    changeSpeakerContract,
    changeSpeakerPartip,
    saveTime,
    saveUrl,
    changeMode,
    moveSlot,
    showDeclined,
    delTheme,
    format
  } = props;

  function menuSectUp() {
    handleClose();
    sectUp(indexSect);
  }

  function menuSectDown() {
    handleClose();
    sectDown(indexSect);
  }

  function menuSectDelete() {
    handleClose();
    sectionOnDelete();
  }


  function onEdit() {
    handleClose();
    editSectionName();
  }

  function onSave() {
    if (sectName != "") {
      sectionNameChange(sectName);
    }
  }
  
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    if (slot && sectName == "") {
      setSectName(slot.name);
    }
  },[slot,sectName]);
  

  return (
    <div className="line-theme" data-open={isOpen}>
      <div
        className={
          !isOpen
            ? "line-constructor title-constructor"
            : "line-constructor title-constructor open"
        }
      >
        <div className="time" />
        <div className="move" />
        <div className="name-parent">
          <div className="left-name">
            {/* <div className={isOpen?"section_arrow-down":"section_arrow-right"} onClick={()=>setOpen(!isOpen)}></div> */}
            <div className="collapse-icon" onClick={() => setOpen(!isOpen)}>
              {/* <span className={isOpen?"section_arrow-down":"section_arrow-right"}></span> */}
              <i className="icon-arrow-up" />
            </div>
            <span>{slot.name}</span>
          </div>
          <div
            className="add-theme-button"
            onClick={() => addTheme(indexSect)}
            style={{ width: 100 }}
          >
            <img
              src={addLogo}
              style={{ width: 30, height: 30, marginRight: 5 }}
            />
            <span>{t("add_slot")}</span>
          </div>
        </div>
        <div className="options" style={{ marginRight: 10 }}>
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={menuSectUp}>{t("move_up")}</MenuItem>
            <MenuItem onClick={menuSectDown}>{t("move_down")}</MenuItem>
            <MenuItem onClick={onEdit}>{t("edit_section")}</MenuItem>
            <MenuItem onClick={menuSectDelete}>{t("del_section")}</MenuItem>
          </Menu>
          <MoreIcon onClick={openMenu} />
        </div>
      </div>
      <div className="list-theme" style={{ maxHeight: isOpen ? 2000 : 0 }}>
        {slot.slots &&
          slot.slots.length > 0 &&
          slot.slots.map((item, slotIndex) => (
            <SlotItem
              item={item}
              mainIndex={indexSect}
              index={slotIndex}
              format={format}
              themes={themes}
              delTheme={delTheme}
              addSpeaker={addSpeaker}
              delSpeaker={delSpeaker}
              speakers={speakers}
              plusDuration={plusDuration}
              minusDuration={minusDuration}
              rooms={rooms}
              changeMode={(e,item) => changeMode(e, item,indexSect, slotIndex)}
              changeThemes={(e, eOld) =>
                changeThemes(e, eOld, indexSect, slotIndex)
              }
              changeRoom={(a, b) => changeRoom(a, b, indexSect, slotIndex)}
              changeSpeakers={(e, i) =>
                changeSpeakers(e, indexSect, slotIndex, i)
              }
              changeSpeakerPartip={(e, i) =>
                changeSpeakerPartip(e, indexSect, slotIndex, i)
              }
              changeSpeakerContract={(e, i) =>
                changeSpeakerContract(e, indexSect, slotIndex, i)
              }
              saveTime={saveTime}
              saveUrl={saveUrl}
              moveSlot={moveSlot}
              delSlot={() => delSlot(indexSect, slotIndex)}
              unbindSlot={() => unbindSlot(indexSect, slotIndex)}
              showDeclined={showDeclined}
              editTheme={editTheme}
            />
          ))}
      </div>
    </div>
  );
}
