import React from "react";

import { connect } from "react-redux";
import { history } from "../_helpers";
import { userService } from "../_services";

function setStorage(name, val) {
  localStorage.setItem(name, val);
}

class VKPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      pwd: "",
      submitted: false,
      getCb: false,
      isNew: false,
      agreeOk: false,
      slotInfoOpen: false,
    };
  }

  componentDidMount() {
    const self = this;
    let str = window.location.href;

    let userType = localStorage.getItem("nc_usertype");

    console.log(str);

    let str_arr = str.split("code=");
    if (str_arr.length > 1) {
      str = str_arr[1];
      str_arr = str.split("&state");
      str = str_arr[0];

      localStorage.setItem("user", "{}");

      let urlStr = `api/token/vkontakte?type=${userType}&code=${str}`;

      if (userType === "") urlStr = `api/token/vkontakte?code=${str}`;

      userService.getWrapper(
        urlStr,
        (data) => {
          console.log(data);
          if (data && data.data && data.data.token) {
            if (data.data.is_agreement_accepted === false) {
              setStorage("token_tmp", data.data.token);
              setStorage("token", "");
              history.push("/agreement");
            } else {
              // localStorage.setItem('navic_agree','1');
              setStorage("token", data.data.token);
              let nc_red = localStorage.getItem("navic_redirect");
              localStorage.setItem("navic_redirect", "");

              if (nc_red) {
                localStorage.setItem("tickets_open", "1");
                history.push(nc_red);
              } else history.push("/");
            }
          }
        },
        (error) => {
          if (error !== "") {
            self.setState({ error, isLoading: false });

            if (error === "Error: Request failed with status code 404") {
              history.push("/registration");
            } else {
              alert(error);
            }
          } else alert(error);
        }
      );
    }
  }

  clickAgr = () => {
    // this.setState({slotInfoOpen:true});
    window.open("https://naviconf.com/privacy-policy");
  };

  handleSubmit = (e) => {
    const { agreeOk, token, id } = this.state;

    if (agreeOk) {
      localStorage.setItem(`navic_agree_${id}`, "1");
      setStorage("token", token);

      let nc_red = localStorage.getItem("navic_redirect");
      localStorage.setItem("navic_redirect", "");

      if (nc_red) {
        localStorage.setItem("tickets_open", "1");
        history.push(nc_red);
      } else history.push("/");
    }
  };

  render() {
    return <div>Loading...</div>;
  }
}

function mapStateToProps(state) {
  const { loggingIn, error } = state.authentication;
  return {
    loggingIn,
    error,
  };
}

const connectedVKPage = connect(mapStateToProps)(VKPage);
export { connectedVKPage as VKPage };
