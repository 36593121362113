import React from "react";

import "../css/style.css";

import i18next from "i18next";

export class SettingsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false };
  }

  componentDidMount() {}

  render() {
    
    return (
      <div className="layout">
        <h1 className="layout__title">{i18next.t("settings")}</h1>
        <div>{i18next.t("used_corp_licension")}</div>
      </div>
    );
  }
}
