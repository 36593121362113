import React from "react";

import { connect } from "react-redux";
import { history, store } from "../_helpers";
import { userService } from "../_services";

function setStorage(name, val) {
  localStorage.setItem(name, val);
}

class LeaderPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      pwd: "",
      submitted: false,
      getCb: false,
      isNew: false,

      agreeOk: false,
      slotInfoOpen: false,
    };

    store.subscribe(this.storeChange);
  }

  storeChange = (e) => {
    console.log(e);
    console.log(store.getState());
  };

  componentDidMount() {
    const self = this;
    let str = window.location.href;

    let userType = localStorage.getItem("nc_usertype");

    console.log(str);

    let str_arr = str.split("code=");
    console.log(str_arr);
    if (str_arr.length > 1) {
      str = str_arr[1];

      console.log("SS", str);

      localStorage.setItem("user", "{}");

      let urlStr = `api/token/leader-id?type=${userType}&code=${str}`;

      if (userType == "") urlStr = `api/token/leader-id?code=${str}`;

      console.log(urlStr);

      userService.getWrapper(
        urlStr,
        (data) => {
          console.log(data);
          if (data && data.data && data.data.token) {
            if (data.data.is_agreement_accepted == false) {
              setStorage("token_tmp", data.data.token);
              setStorage("token", "");
              history.push("/agreement");
            } else {
              // localStorage.setItem('navic_agree','1');
              setStorage("token", data.data.token);

              let nc_red = localStorage.getItem("navic_redirect");
              localStorage.setItem("navic_redirect", "");

              if (nc_red) {
                localStorage.setItem("tickets_open", "1");
                history.push(nc_red);
              } else history.push("/");
            }
          }
        },
        (error) => {
          if (error != "") {
            self.setState({ error, isLoading: false });
            //  alert(error);

            if (error == "Error: Request failed with status code 404") {
              history.push("/registration");
            } else alert(error);
          } else alert(error);
        }
      );
    }
  }

  clickAgr = () => {
    // this.setState({slotInfoOpen:true});
    window.open("https://naviconf.com/privacy-policy");
  };

  handleSubmit = (e) => {
    const { getCb, isNew, agreeOk, token, id } = this.state;

    if (agreeOk) {
      localStorage.setItem(`navic_agree_${id}`, "1");
      setStorage("token", token);

      let nc_red = localStorage.getItem("navic_redirect");
      localStorage.setItem("navic_redirect", "");

      if (nc_red) {
        localStorage.setItem("tickets_open", "1");
        history.push(nc_red);
      } else history.push("/");
    }
  };

  render() {
    return <div>Loading...</div>;
  }
}

function mapStateToProps(state) {
  const { loggingIn, error } = state.authentication;
  return {
    loggingIn,
    error,
  };
}

const connectedLPage = connect(mapStateToProps)(LeaderPage);
export { connectedLPage as LeaderPage };
