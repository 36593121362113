import React from "react";
import CustomizedButton from "../_components/buttons/CustomizedButton";
import i18next from "i18next";
import { initLanguage } from "../_helpers";
import { store } from "../_helpers";
import { userActions } from "../_actions";
import { userService } from "../_services";
import { TextField } from "@mui/material";
import { responseErrorToText } from "../_helpers";
import { Snack } from "../_components";
import { NoAuthPage } from "./NoAuthPage";
import { history } from "../_helpers";
import { cn as lang_cn } from "../_lang/cn.json";
import { en as lang_en } from "../_lang/en.json";
import { ru as lang_ru } from "../_lang/ru.json";

const loginIsEmail = process.env.REACT_APP_LOGIN_IS_EMAIL;

export default class PasswordForgotPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      alertShow: false,
      errType: "error",
      snackText: "",
    };
  }

  componentDidMount() {
    let lang = localStorage.getItem("navic_lang") ?? "ru";
    this.setState({ lang });
  }

  getI18N = (textKey) => {
    const { lang } = this.state;

    if (!lang) return;

    if (lang == "ru") {
      if (Object.keys(lang_ru.translation).indexOf(textKey) >= 0)
        return lang_ru.translation[textKey];
    }

    if (lang == "en")
      if (Object.keys(lang_en.translation).indexOf(textKey) >= 0)
        return lang_en.translation[textKey];

    if (lang == "cn")
      if (Object.keys(lang_cn.translation).indexOf(textKey) >= 0)
        return lang_cn.translation[textKey];

    return textKey;
  };

  sendResetEmail = (e) => {

    let self = this;

    e.preventDefault();
    store.dispatch(userActions.loading(true));
    userService.postWrapper(
      "api/password/forgot",
      { email: this.state.email },
      (data) => {
        store.dispatch(userActions.loading(false));
        this.setState({
          snackText:
          self.getI18N("email_with_reset_password_link_sent") + this.state.email,
          alertShow: true,
          errType: "success",
        });
      },
      (error) => {
        store.dispatch(userActions.loading(false));
        this.setState({
          snackText: responseErrorToText(error),
          alertShow: true,
          errType: "error",
        });
      }
    );
  };

  render() {
    const form = (
      <form onSubmit={this.sendResetEmail}>
        <div>
          <TextField
            label="email"
            onChange={(e) => this.setState({ email: e.target.value })}
            variant="standard"
          />
        </div>
        <CustomizedButton
          type="submit"
          title={this.getI18N("reset_password")}
          prim
          className="form-button"
        />
      </form>
    );
    const snack = (
      <Snack
        open={this.state.alertShow}
        variant={this.state.errType}
        text={this.state.snackText}
        onClose={() => this.setState({ alertShow: false })}
      />
    );
    return <NoAuthPage form={form} snack={snack} />;
  }
}
