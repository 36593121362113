import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextFieldI18N from "../_components/TextFieldI18N";

const LANG_LIST = ['ru','en','cn'];

export default function SettingsDialog(props) {
  const [name, setName] = React.useState("");
  const [name_i18n, setNameI18N] = React.useState({});
  const [count, setCount] = React.useState(0);
  
  const { handleClose, dialogOpen, dName, dNameI18N, text_cancel, text_save, text_section_name} = props;

  React.useEffect(() => {
    if (count === 0) {
      setName(dName);
      setNameI18N(dNameI18N);
    }
  },[count,dName]);

  function saveData() {
    if (name !== "") {
      handleClose({ name, name_i18n });
      setCount(0);
    }
  }

  function cancelData() {
    handleClose(null);
    setCount(0);
  }

  return (
    <Dialog open={dialogOpen}>
      <DialogTitle>{text_section_name}</DialogTitle>
      <DialogContent>
        <TextFieldI18N
          autoFocus
          margin="dense"
          id="name"
          label="Имя"
          fullWidth
          variant="standard"
          value={name_i18n ?? {}}
          onChange={(e,tabName) => {
            let tmpObj = name_i18n;
            tmpObj[tabName] = e.target.value
            setNameI18N(tmpObj);
            setName(tmpObj['ru']);
            setCount(count + 1);
          }}
          langList={LANG_LIST}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={saveData}>{text_save}</Button>
        <Button onClick={cancelData}>{text_cancel}</Button>
      </DialogActions>
    </Dialog>
  );
}
