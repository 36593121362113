import React from "react";
import "../css/style.css";

import { connect } from "react-redux";
import i18next from "i18next";
import { CircularProgress } from "@material-ui/core";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { confService } from "../_services";
import PartItem from "./PartItem";

class PartPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      team: [],
      nameF: "",
      confF: "",
      ticketF: "",
      dateF: "",
      sortNameDesc: false,
    };
  }

  componentDidMount() {
    this.getTeam();
  }

  getTickets = (id) => {
    // userService.getAsync(['api/ticket-type?conference_id='+id,'api/ticket-type?conference_id='+id],function(data){console.log(data)});
  };

  getConfs = () => {};

  getTeam = () => {
    const self = this;

    console.log(this.props.user);

    confService.confListStudents(
      (data) => {
        self.setState({ team: [...data], isLoading: false });
      },
      (err) => {
        console.log(err);
      }
    );
  };

  editFilter = (name, val) => {
    if (name === "name") this.setState({ nameF: val });
    else if (name === "conf") this.setState({ confF: val });
    else if (name === "date") this.setState({ dateF: val });
    else if (name === "ticket") this.setState({ ticketF: val });
  };

  render() {
    const self = this;
    const { isLoading, team, nameF, confF, dateF, ticketF, sortNameDesc } =
      this.state;

    return (
      <div className="layout">
        <div className="layout__contains">
          <h1 className="layout__title">{i18next.t("partips")}</h1>
        </div>

        <div className="tbl">
          <div className="tbl__top">
            <div className="tbl__items">
              <div className="tbl__item tbl__item--city">
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    onClick={() =>
                      this.setState({ sortNameDesc: !sortNameDesc })
                    }
                  >
                    {sortNameDesc ? (
                      <ExpandLess fontSize="large" />
                    ) : (
                      <ExpandMore fontSize="large" />
                    )}
                  </div>
                  <input
                    type="text"
                    placeholder={i18next.t("search_for_name")}
                    value={nameF}
                    onChange={(e) => self.editFilter("name", e.target.value)}
                  />
                </div>
              </div>
              <div className="tbl__item tbl__item--city">
                <input
                  type="text"
                  placeholder={i18next.t("search_for_event")}
                  value={confF}
                  onChange={(e) => self.editFilter("conf", e.target.value)}
                />
              </div>
              <div className="tbl__item tbl__item--text">
                <input
                  type="text"
                  placeholder={
                    i18next.t("search_for") +
                    " " +
                    i18next.t("search_type_ticket")
                  }
                  value={ticketF}
                  onChange={(e) => self.editFilter("ticket", e.target.value)}
                />
              </div>
              <div className="tbl__item tbl__item--text">
                <input
                  type="text"
                  placeholder={i18next.t("date_search")}
                  value={dateF}
                  onChange={(e) => self.editFilter("date", e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="tbl__body">
            {team.length > 0 &&
              team
                .filter(function (e) {
                  if (!e) return false;

                  return (
                    (e.user_name || "")
                      .toLowerCase()
                      .indexOf(nameF.toLowerCase()) >= 0 &&
                    e.conf_name.toLowerCase().indexOf(confF.toLowerCase()) >=
                      0 &&
                    e.updated_at.toLowerCase().indexOf(dateF.toLowerCase()) >=
                      0 &&
                    e.ticket_name
                      .toLowerCase()
                      .indexOf(ticketF.toLowerCase()) >= 0
                  );
                })
                .sort(function (a, b) {
                  var nameA = a.user_name.toLowerCase(),
                    nameB = b.user_name.toLowerCase();

                  if (!sortNameDesc) {
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                  } else {
                    if (nameA < nameB) return 1;
                    if (nameA > nameB) return -1;
                    return 0;
                  }
                })
                .map((item, index) => <PartItem data={item} />)}
          </div>
        </div>

        {isLoading && (
          <div className="shadow">
            <div className="shadow_circle">
              <CircularProgress />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedPartPage = connect(mapStateToProps)(PartPage);
export { connectedPartPage as PartPage };
